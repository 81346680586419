import { Flex, Switch, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { getWidgetData, handleWidgetShowActualsPeriod } from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const ShowActualsPeriod = () => {
  const dispatch: AppDispatch = useDispatch();

  const widgetData = useSelector(getWidgetData);

  const handleActualsPeriodChange = (value: boolean) => {
    dispatch(handleWidgetShowActualsPeriod(value));
  };

  return (
    <Flex justifyContent={'space-between'} pr={2} alignItems={'center'} mb={2}>
      <Text color={'black.900'} fontWeight={'600'}>
        Show actuals period
      </Text>
      <Switch
        isChecked={widgetData.showActualsPeriods}
        ml={4}
        onChange={(event) => handleActualsPeriodChange(event.target.checked)}
      ></Switch>
    </Flex>
  );
};

export default ShowActualsPeriod;
