import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  handleWidgetDonutHoleSize,
  updateChartInstrument,
  fetchDashboardChart,
  getKpiChartData,
  getDashboardChartId,
  fetchDashboardGraph,
  getChartType,
} from 'redux/DashboardSlice';
import { getAllScenarios } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface Properties {
  chartDonutSize?: any;
  isEditable?: any;
}
const DonutHole = ({ chartDonutSize, isEditable }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const chartId = useSelector(getDashboardChartId);
  const pieChartData = useSelector(getKpiChartData);
  const allScenarios = useSelector(getAllScenarios);
  const chartType = useSelector(getChartType);
  const scenarioId = allScenarios.find((item: any) => item.name === 'Baseline')?.id;

  const [donutHoleSize, setDonutHoleSize] = useState(0);

  useMemo(() => {
    if (isEditable && chartDonutSize) {
      setDonutHoleSize(chartDonutSize);
    }
  }, [chartDonutSize]);

  const updateChart = useCallback(
    debounce((value: any) => {
      if (pieChartData) {
        dispatch(
          updateChartInstrument({
            ...pieChartData,
            formatting: {
              ...pieChartData.formatting,
              donutHoleSize: value,
            },
          }),
        ).then(() => {
          dispatch(fetchDashboardChart(chartId));
          if (chartType === 1) {
            dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
          }
        });
      }
    }, 500),
    [dispatch, pieChartData, chartId],
  );

  const handleWidgetChange = (value: any) => {
    dispatch(handleWidgetDonutHoleSize(value));
    setDonutHoleSize(value);
    updateChart(value);
  };

  return (
    <Flex flexDir={'column'} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Donut hole size
      </Text>
      <NumberInput
        width={'100%'}
        size={'sm'}
        backgroundColor={'#FAFAFA'}
        min={0}
        max={100}
        defaultValue={donutHoleSize}
        value={donutHoleSize}
        onChange={(value) => handleWidgetChange(value)}
        format={(value) => `${value}%`}
        parse={(value) => value.replace(/%/, '')}
      >
        <NumberInputField borderRadius={'6px'} paddingRight={'2rem'} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  );
};

export default DonutHole;
