/* eslint-disable consistent-return */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Align } from 'chart.js';

import { FormatProperties } from 'models/response/modelOutput.interface';

import formatValue from './FormatValues';

ChartJS.register(ChartDataLabels);

interface Properties {
  chartFormatting?: any;
  chartType?: string;
  isLegend?: boolean;
  pageWidth?: number;
  formatProperties?: FormatProperties;
  pageName?: string;
  aspectRatio: number;
}

const UNIT_MULTIPLIERS: any = {
  k: 1000,
  mn: 1_000_000,
  bn: 1_000_000_000,
  auto: 1,
};

const getDashboradChartOptions = ({
  chartFormatting,
  isLegend = false,
  pageWidth = 0,
  formatProperties,
  pageName = '',
  aspectRatio,
}: Properties) => {
  const formatDataLabel = (value: any) => {
    // let total: any = 0;
    // context.chart.data.datasets.forEach((dataset: any) => {
    //   total += dataset.data[context.dataIndex];
    // });
    if (chartFormatting?.units === 'auto') {
      return `${value
        .toFixed(chartFormatting?.decimalPlaces)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }

    return `${(value / UNIT_MULTIPLIERS[chartFormatting?.units])
      .toFixed(chartFormatting?.decimalPlaces)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${chartFormatting?.units}`;
  };

  return {
    aspectRatio,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: { display: false },
      tooltip: {
        color: '#979797',
        callbacks: {
          label(context: any) {
            const label = context.dataset.label || '';
            const value = context.row || '';

            if (formatProperties?.measure === '%') {
              return `${label}: ${value * 100}`;
            }
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: isLegend,
        position: 'top' as const,
        align: 'end' as Align,
        labels: {
          boxWidth: pageWidth < 576 ? 10 : 15,
          boxHeight: pageWidth < 576 ? 10 : 15,
          color: '#979797',
        },
      },
      datalabels: {
        display: chartFormatting?.displayValue,
        color: 'black',
        font: {
          size: 9,
        },
        align: 'end',
        anchor: 'end',
        position: 'top',
        formatter: formatDataLabel,
      },
    },
    scales: {
      x: {
        stacked: chartFormatting?.stackBar,
        grid: {
          color: 'transparent',
          borderColor: '#97979766',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          color: '#212121',
          padding: 10,
        },
      },
      y: {
        beginAtZero: true,
        stacked: chartFormatting?.stackBar,
        grid: {
          color: '#97979766',
          borderColor: 'transparent',
          tickColor: 'transparent',
        },
        ticks: {
          color: '#212121',
          padding: 0,
          callback(value: any) {
            if (pageName === 'drivers') {
              if (formatProperties?.measure === '%') {
                return `${value * 100}%`;
              }
              return `${formatValue(value, formatProperties as FormatProperties)}`;
            }

            return value.toLocaleString();
          },
        },
      },
    },
  };
};

export default getDashboradChartOptions;
