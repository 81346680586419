import { useSelector } from 'react-redux';

import { getChartType, getDashboardWidgetType, getWidgetData } from 'redux/DashboardSlice';

import DashboardModalConditionalFormatting from './ConditionalFormatting';
import DashboardModalDecimalPlaces from './DecimalPlaces';
import DashboardModalNegativeDisplay from './NegativeDisplay';
import DashboardModalSparkLine from './SparkLine';
import DashboardModalUnits from './Units';
import DonutHole from './DonutHole';
import DisplayValue from './DisplayValues';
import BarDisplay from './BarDisplay';
import LineDisplay from './LineDisplay';

const DashboardWidgetDisplay = ({ chartFormatting, isEditableModal }: any) => {
  const widgetType = useSelector(getDashboardWidgetType);
  const chartType = useSelector(getChartType);
  const widgetData = useSelector(getWidgetData);
  return (
    <>
      {widgetType === 2 ? (
        <>
          {chartType === 3 ? (
            <BarDisplay
              chartFormatting={chartFormatting?.formatting?.donutHoleSize}
              isEditable={isEditableModal}
            />
          ) : chartType === 2 ? (
            <LineDisplay />
          ) : (
            <>
              <DonutHole
                chartDonutSize={chartFormatting?.formatting?.donutHoleSize}
                isEditable={isEditableModal}
              />
              <DisplayValue
                chartDisplayValue={chartFormatting?.formatting?.displayValue}
                isEditable={isEditableModal}
              />
              {(widgetData.displayValues || chartFormatting?.formatting?.displayValue) && (
                <>
                  <DashboardModalUnits
                    chartUnits={chartFormatting?.formatting?.units}
                    isEditable={isEditableModal}
                  />
                  <DashboardModalDecimalPlaces
                    chartDecimalPlaces={chartFormatting?.formatting?.decimalPlaces}
                    isEditable={isEditableModal}
                  />
                  <DashboardModalNegativeDisplay
                    chartNegativeDisplay={chartFormatting?.formatting?.negativeDisplay}
                    isEditable={isEditableModal}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <DashboardModalSparkLine
            chartSparkline={chartFormatting?.formatting?.sparkline}
            isEditable={isEditableModal}
          />
          <DashboardModalUnits
            chartUnits={chartFormatting?.formatting?.units}
            isEditable={isEditableModal}
          />
          <DashboardModalDecimalPlaces
            chartDecimalPlaces={chartFormatting?.formatting?.decimalPlaces}
            isEditable={isEditableModal}
          />
          <DashboardModalNegativeDisplay
            chartNegativeDisplay={chartFormatting?.formatting?.negativeDisplay}
            isEditable={isEditableModal}
          />
          {widgetType !== 3 && (
            <DashboardModalConditionalFormatting
              chartConditionalFormatting={chartFormatting?.conditional_formatting}
              isEditable={isEditableModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default DashboardWidgetDisplay;
