import React from 'react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginRight?: string;
}

const PieChartIcon = ({
  width = 18,
  height = 18,
  color = 'white.900',
  marginRight,
}: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      color={color}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M7.2 0V16C3.144 15.6 0 12.168 0 8C0 3.832 3.144 0.4 7.2 0ZM8.824 0V7.192H16C15.624 3.4 12.608 0.376 8.824 0ZM8.824 8.808V16C12.616 15.624 15.624 12.6 16 8.808H8.824Z'
        fill='#787878'
      />
    </svg>
  );
};

export default PieChartIcon;
