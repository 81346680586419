import { Flex, Switch, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useMemo } from 'react';

import {
  handleWidgetStackBars,
  fetchDashboardChart,
  updateChartInstrument,
  getKpiChartData,
  getDashboardChartId,
  getChartType,
  fetchDashboardGraph,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import { getAllScenarios } from 'redux/ScenarioSlice';

interface Properties {
  chartStackBar?: any;
  isEditable?: any;
}
const StackBars = ({ chartStackBar }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const allScenarios = useSelector(getAllScenarios);
  const scenarioId = allScenarios.find((item: any) => item.name === 'Baseline')?.id;
  const pieChartData = useSelector(getKpiChartData);
  const [stackBar, setStuckBar] = useState(pieChartData?.formatting?.stuckBar || false);
  const chartType = useSelector(getChartType);
  const chartId = useSelector(getDashboardChartId);

  useMemo(() => {
    if (chartStackBar) {
      setStuckBar(chartStackBar);
    }
  }, [chartStackBar]);

  const chartPayload = useMemo(() => {
    if (pieChartData) {
      const payload = pieChartData;
      return payload;
    }
    return null;
  }, [pieChartData]);

  const handleStackBarsChange = (value: boolean) => {
    setStuckBar(value);
    dispatch(handleWidgetStackBars(value));
    dispatch(
      updateChartInstrument({
        ...chartPayload,
        formatting: {
          ...chartPayload.formatting,
          stackBar: value,
        },
      }),
    ).then(() => {
      dispatch(fetchDashboardChart(chartId));
      if (chartType === 3) {
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      }
    });
  };

  return (
    <Flex justifyContent={'space-between'} pr={2} alignItems={'center'} mb={2}>
      <Text color={'black.900'} fontWeight={'600'}>
        Stack bars
      </Text>
      <Switch
        isChecked={stackBar}
        ml={4}
        onChange={(event) => handleStackBarsChange(event.target.checked)}
      ></Switch>
    </Flex>
  );
};

export default StackBars;
