import React from 'react';
import { useSelector } from 'react-redux';

import { getWidgetData } from 'redux/DashboardSlice';

import DisplayMarkers from './DisplayMarkers';
import LeftAxes from './LeftAxes';
import ShowActualsPeriod from './ShowActualsPeriod';
import ShowLabels from './ShowLabels';
import DashboardModalDecimalPlaces from './DecimalPlaces';
import DashboardModalNegativeDisplay from './NegativeDisplay';
import DashboardModalUnits from './Units';

const LineDisplay = () => {
  const widgetData = useSelector(getWidgetData);
  return (
    <>
      <DisplayMarkers />
      <ShowActualsPeriod />
      <LeftAxes />
      <ShowLabels />
      {widgetData.showLabels && (
        <>
          <DashboardModalUnits />
          <DashboardModalDecimalPlaces />
          <DashboardModalNegativeDisplay />
        </>
      )}
    </>
  );
};

export default LineDisplay;
