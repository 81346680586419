/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */

import React, { useEffect } from 'react';
// import { Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';

import { AppDispatch } from 'utils/GlobalHelpers';
import {
  fetchDashboardGraph,
  getDashboardChartId,
  getDashboardSelectedScenario,
  getSelectedRow,
  getTableIndicator,
  handleSelectedRow,
  updateChartMetric,
} from 'redux/DashboardSlice';
import CustomToast from 'components/CustomToast';

import DashboardModalConditionalFormatting from '../Display/ConditionalFormatting';

import CellFormatting from './CellFormatting';
import TextColor from './TextColor';
import CellColor from './CellColor';
import Indentation from './Indentation';

const DashboarWidgetFormat = ({ chartFormatting, isEditableModal }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const chartId = useSelector(getDashboardChartId);
  const selectedRow = useSelector(getSelectedRow);
  const toast = useToast();
  const tableIndicator = useSelector(getTableIndicator);

  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;

  const updateMetric = (indicator: any) => {
    if (!selectedRow) {
      toast({
        duration: 2000,
        render: () => <CustomToast title={'Please Select Table Row'} status='error' />,
      });
    } else {
      dispatch(updateChartMetric(indicator)).then(() => {
        dispatch(
          fetchDashboardGraph({
            chartId,
            scenarioId,
            requestBody: [],
          }),
        );
      });
    }
  };

  useEffect(() => {
    if (tableIndicator && selectedRow) {
      let updatedIndicator = null;
      tableIndicator.data?.map((indicator: any) => {
        if (indicator?.metric.id === selectedRow?.id) {
          updatedIndicator = indicator.metric;
        }
        return null;
      });
      dispatch(handleSelectedRow(updatedIndicator));
    }
  }, [tableIndicator]);
  return (
    <>
      <CellFormatting updateMetric={updateMetric} />
      <TextColor updateMetric={updateMetric} />
      <CellColor updateMetric={updateMetric} />
      <Indentation updateMetric={updateMetric} />
      {/* <Box>
        <Text color={'black.900'} fontWeight={'600'}>
          Cell Borders
        </Text>
      </Box> */}
      <DashboardModalConditionalFormatting
        chartConditionalFormatting={chartFormatting?.conditional_formatting}
        isEditable={isEditableModal}
      />
    </>
  );
};

export default DashboarWidgetFormat;
