/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  useToast,
  Button,
  MenuList,
  MenuItem,
  NumberInputField,
  NumberInput,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { GoChevronDown } from 'react-icons/go';
import { useState, useEffect } from 'react';

import CustomToast from 'components/CustomToast';
import Plus from 'components/Icons/Plus';
import {
  handleAddConditionalFormat,
  handleConditionalFormat,
  fetchDashboardChart,
  deleteConditionalFormatting,
  updateConditionalFormatting,
  addConditionalFormatting,
  getDashboardChartId,
  getSelectedRow,
  fetchDashboardGraph,
  getDashboardWidgetType,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import RemoveIconComponent from 'components/Icons/RemoveIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import { DASHBOARD_CHART_TYPE } from 'projectConstants';
import { fetchAllScenarios, getAllScenarios } from 'redux/ScenarioSlice';

interface ConditionalFormatItem {
  localId: number;
  color: string;
  condition: string;
  value: string;
  id: string;
}
interface Properties {
  chartConditionalFormatting?: any;
  isEditable?: any;
}

const DashboardModalConditionalFormatting = ({
  chartConditionalFormatting,
  isEditable,
}: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const chartId = useSelector(getDashboardChartId);
  const selectedRow = useSelector(getSelectedRow);
  const widgetType = useSelector(getDashboardWidgetType);

  const toast = useToast();
  const [colorEventData, setColorEventData] = useState<ConditionalFormatItem>();
  const [currentColor, setCurrentColor] = useState<string>();
  const [selectedConditionalFormat, setSelectedConditionalFormat] = useState<
    ConditionalFormatItem[]
  >([]);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;

  useEffect(() => {
    if (chartConditionalFormatting) {
      const formattingData =
        selectedRow && widgetType === DASHBOARD_CHART_TYPE.TABLE
          ? chartConditionalFormatting.filter((item: any) => item.metric_id === selectedRow.id)
          : chartConditionalFormatting;
      const chartSelectectCondition = formattingData?.map((filter: any, index: any) => {
        return {
          localId: index + 1,
          color: filter.formatting.color,
          condition: filter.value,
          value: filter.formatting.value,
          id: filter.id,
        };
        return chartSelectectCondition;
      });

      setSelectedConditionalFormat(chartSelectectCondition);
      dispatch(handleAddConditionalFormat());
    }
  }, [chartConditionalFormatting, selectedRow]);

  const handleAddingFilter = () => {
    setSelectedConditionalFormat([
      ...selectedConditionalFormat,
      {
        localId: selectedConditionalFormat.length + 1,
        color: '#57519C',
        condition: 'is less than',
        value: '',
        id: '',
      },
    ]);
    dispatch(handleAddConditionalFormat());
  };

  const handleConditionalName = (name: number, itemData: any) => {
    let conditionalId = '';
    let isValueInput = false;
    let conditionName = '';

    const newConditionalFormat = selectedConditionalFormat.map((item: any) => {
      if (item.localId === itemData.localId) {
        isValueInput = item.value.length > 0;
        conditionalId = itemData.id.length > 0 ? itemData.id : conditionalId;
        conditionName = name === 1 ? 'is less than' : name === 2 ? 'is greater than' : 'equal to';

        return {
          ...item,
          condition: conditionName,
        };
      }
      return item;
    });

    if (conditionalId && isValueInput) {
      const payload = {
        name: 'Conditional Formatting',
        formatting: {
          color: itemData.color,
          value: itemData.value,
          priority: itemData.localId,
        },
        operator: 'eq',
        value: conditionName,
        chart_id: chartId,
      };

      dispatch(updateConditionalFormatting({ ...payload, id: conditionalId })).then(() => {
        dispatch(fetchDashboardChart(chartId));
        if (widgetType === DASHBOARD_CHART_TYPE.TABLE) {
          dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        }
      });
    }

    setSelectedConditionalFormat(newConditionalFormat);
    dispatch(handleConditionalFormat(newConditionalFormat));
  };

  const handleRemovingCondition = (itemData: any) => {
    const newConditionalFormat: any = selectedConditionalFormat.filter(
      (filter: any) => filter.localId !== itemData.localId,
    );
    dispatch(deleteConditionalFormatting(itemData.id)).then((response: any) => {
      if (response.type.endsWith('/fulfilled')) {
        toast({
          duration: 2000,
          render: () => <CustomToast title={response?.payload.message} status='success' />,
        });
        dispatch(fetchDashboardChart(chartId));
      }
    });
    setSelectedConditionalFormat(newConditionalFormat);
    dispatch(handleConditionalFormat(newConditionalFormat));
  };

  const handleAddColor = (event: any, itemData: ConditionalFormatItem) => {
    setColorEventData(itemData);
    setCurrentColor(event?.target.value);
  };

  useEffect(() => {
    if (currentColor && colorEventData) {
      const handler = setTimeout(() => {
        let conditionalId = '';
        let isValueInput = false;

        const newConditionalFormat = selectedConditionalFormat.map((item: any) => {
          if (item.localId === colorEventData.localId) {
            isValueInput = item.value.length > 0;
            conditionalId = colorEventData.id.length > 0 ? colorEventData.id : conditionalId;

            return {
              ...item,
              color: currentColor,
            };
          }
          return item;
        });

        if (!isValueInput) {
          toast({
            duration: 2000,
            render: () => <CustomToast title={'Please Input Value'} status='error' />,
          });
          return;
        }

        const payload = {
          name: 'Conditional Formatting',
          formatting: {
            color: currentColor,
            value: colorEventData.value,
            priority: colorEventData.localId,
          },
          operator: 'eq',
          value: colorEventData.condition,
          chart_id: chartId,
          metric_id: selectedRow ? selectedRow?.id : null,
        };

        if (conditionalId) {
          dispatch(updateConditionalFormatting({ ...payload, id: conditionalId })).then(() => {
            dispatch(fetchDashboardChart(chartId));
            if (widgetType === DASHBOARD_CHART_TYPE.TABLE) {
              dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
            }
          });
        } else {
          dispatch(addConditionalFormatting(payload)).then(() => {
            dispatch(fetchDashboardChart(chartId));
            if (widgetType === DASHBOARD_CHART_TYPE.TABLE) {
              dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
            }
          });
        }
      }, 1200);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [currentColor, colorEventData]);

  const handleConditionValue = (values: any, local_id: any) => {
    const newConditionalFormat: any = selectedConditionalFormat.map((item: any) =>
      item.localId === local_id
        ? {
            ...item,
            value: values,
          }
        : item,
    );
    setSelectedConditionalFormat(newConditionalFormat);
    dispatch(handleConditionalFormat(newConditionalFormat));
  };

  return (
    <Flex flexDir={'column'}>
      <Flex alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Text color={'black.900'} fontWeight={'600'}>
          Conditional formatting
        </Text>
        <Plus color='#787878' onClick={handleAddingFilter} />
      </Flex>
      {widgetType === DASHBOARD_CHART_TYPE.TABLE && !selectedRow && (
        <Text textAlign={'center'}>Please Select Table Row</Text>
      )}
      {selectedConditionalFormat.length > 0 &&
        (widgetType === DASHBOARD_CHART_TYPE.KPI ||
          (widgetType === DASHBOARD_CHART_TYPE.TABLE && selectedRow)) && (
          <Flex flexDir={'column'} gap={4}>
            {selectedConditionalFormat.map((item: any) => {
              return (
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                  key={item.localId}
                >
                  <Flex
                    backgroundColor={'#FAFAFA'}
                    flex={1}
                    borderRadius={'6px'}
                    alignItems={'center'}
                    gap={2}
                  >
                    <Box>
                      <Menu variant={'dashboardingModal'}>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              as={Button}
                              padding={'.4rem .5rem'}
                              variant={'dashboardingModal'}
                              color={'#212121'}
                              fontWeight={'600'}
                              width={'100%'}
                            >
                              <Flex
                                color='#787878'
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Flex gap={1.5} alignItems={'center'}>
                                  {item.condition}
                                </Flex>
                                <GoChevronDown size={18} />
                              </Flex>
                            </MenuButton>
                            {isOpen && (
                              <MenuList>
                                <MenuItem onClick={() => handleConditionalName(1, item)}>
                                  is less than
                                </MenuItem>
                                <MenuItem onClick={() => handleConditionalName(2, item)}>
                                  greater than
                                </MenuItem>
                                <MenuItem onClick={() => handleConditionalName(3, item)}>
                                  equal to
                                </MenuItem>
                              </MenuList>
                            )}
                          </>
                        )}
                      </Menu>
                    </Box>
                    <Box w={'40%'}>
                      <NumberInput
                        value={item.value}
                        onChange={(value) => handleConditionValue(value, item.localId)}
                      >
                        <NumberInputField paddingInline={1} borderRadius={'6px'} height={8} />
                      </NumberInput>
                    </Box>
                    <Flex flexDirection={'column'} alignItems='center' justifyContent={'center'}>
                      <Text>A</Text>
                      <input
                        type='color'
                        value={item.color}
                        onChange={(event: any) => handleAddColor(event, item)}
                        style={{ width: '24px', height: '15px' }}
                      ></input>
                    </Flex>
                  </Flex>
                  <RemoveIconComponent
                    color={'black.900'}
                    onClick={() => handleRemovingCondition(item)}
                  />
                </Flex>
              );
            })}
          </Flex>
        )}
    </Flex>
  );
};

export default DashboardModalConditionalFormatting;
