import React from 'react';
import { useSelector } from 'react-redux';

import { getWidgetData } from 'redux/DashboardSlice';

import LeftAxes from './LeftAxes';
import ShowActualsPeriod from './ShowActualsPeriod';
import ShowLabels from './ShowLabels';
import StackBars from './StackBars';
import DashboardModalDecimalPlaces from './DecimalPlaces';
import DashboardModalNegativeDisplay from './NegativeDisplay';
import DashboardModalUnits from './Units';

interface Properties {
  chartFormatting?: any;
  isEditable?: any;
}

const BarDisplay = ({ chartFormatting, isEditable }: Properties) => {
  const widgetData = useSelector(getWidgetData);
  return (
    <>
      <StackBars chartStackBar={chartFormatting?.formatting?.stackBar} isEditable={isEditable} />
      <ShowActualsPeriod />
      <LeftAxes />
      <ShowLabels />
      {widgetData.showLabels && (
        <>
          <DashboardModalUnits />
          <DashboardModalDecimalPlaces />
          <DashboardModalNegativeDisplay />
        </>
      )}
    </>
  );
};

export default BarDisplay;
