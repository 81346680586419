/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Flex, Menu, Text, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  handleWidgetNegativeDisplay,
  getWidgetData,
  fetchDashboardChart,
  getKpiChartData,
  updateChartInstrument,
  fetchDashboardGraph,
  getChartType,
  getDashboardChartId,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface DashboardModalNegativeDisplayProperties {
  chartNegativeDisplay?: any;
  isEditable?: any;
}

const DashboardModalNegativeDisplay = ({
  chartNegativeDisplay,
  isEditable,
}: DashboardModalNegativeDisplayProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const widgetData = useSelector(getWidgetData);
  const chartId = useSelector(getDashboardChartId);
  const kpiChartData = useSelector(getKpiChartData);
  const chartType = useSelector(getChartType);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const [negativeDisplay, setNegativeDisplay] = useState('Minus Sign' as any);

  useMemo(() => {
    if (isEditable && chartNegativeDisplay) {
      chartNegativeDisplay === 1
        ? setNegativeDisplay('Minus Sign')
        : chartNegativeDisplay === 2
        ? setNegativeDisplay('Brackets')
        : setNegativeDisplay('Minus Sign');
    }
  }, [chartNegativeDisplay]);

  useMemo(() => {
    if (widgetData.negativeDisplay) {
      widgetData.negativeDisplay === 1
        ? setNegativeDisplay('Minus Sign')
        : widgetData.negativeDisplay === 2
        ? setNegativeDisplay('Brackets')
        : setNegativeDisplay('Minus Sign');
    }
  }, [widgetData.negativeDisplay]);

  const chartPayload = useMemo(() => {
    if (kpiChartData) {
      const payload = kpiChartData;
      return payload;
    }
    return null;
  }, [kpiChartData]);

  const handleNegativeDisplay = (value: number) => {
    dispatch(handleWidgetNegativeDisplay(value));
    dispatch(
      updateChartInstrument({
        ...chartPayload,
        formatting: {
          ...chartPayload.formatting,
          negativeDisplay: value === 1 ? 'Minus Sign' : value === 2 ? 'brackets' : 'Minus Sign',
        },
      }),
    ).then(() => {
      dispatch(fetchDashboardChart(chartId));
      if (chartType === 1 || chartType === 3) {
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      }
    });
  };

  return (
    <Flex flexDir={'column'} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Negative display
      </Text>
      <Menu variant={'dashboardingModal'}>
        {({ isOpen }) => (
          <>
            <MenuButton width={'100%'}>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                backgroundColor={'#FAFAFA'}
                border={'1px #D9D9D9 solid'}
                padding={'.5rem .8rem'}
                borderRadius={'6px'}
                color={'black.600'}
                fontSize={'2xs'}
              >
                <Text>{negativeDisplay}</Text>
                <GoChevronDown size={18} />
              </Flex>
            </MenuButton>
            {isOpen && (
              <MenuList>
                <MenuItem onClick={() => handleNegativeDisplay(1)}>Minus sign</MenuItem>
                <MenuItem onClick={() => handleNegativeDisplay(2)}>Brackets</MenuItem>
              </MenuList>
            )}
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default DashboardModalNegativeDisplay;
