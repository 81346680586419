/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Text } from '@chakra-ui/react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { CHART_COLORS } from 'projectConstants';
import { getBaseScenario } from 'redux/ScenarioSlice';
import getDashboradChartOptions from 'utils/dashboardChartOptions';
import { gePieDimFilter } from 'redux/DashboardSlice';
import getFilterCondition from 'utils/TimeGranularityCondition';

interface Properties {
  chartFormatting?: any;
  graphData?: any;
  timeGranularityFilter?: any;
}
const BarChart = ({ timeGranularityFilter, chartFormatting, graphData }: Properties) => {
  const baseScenario = useSelector(getBaseScenario);

  const startDateObject = new Date(baseScenario?.start_date);
  startDateObject.setMonth(startDateObject.getMonth() - 1);
  const [chartData, setChartData] = useState<any>([]);
  const [color, setColor] = useState<any>([]);
  const pieDimeFilter = useSelector(gePieDimFilter);
  const [singleIndicatorNoDimData, setSingleIndicatorNoDimData] = useState([]);
  const [multipleIndicatorNoDimData, setMultipleIndicatorNoDimData] = useState<any>([]);
  const [oneDimeensionFilter, setOneDimeensionFilter] = useState<any>([]);
  const [labels, setLabels] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]);

  const filterValues = (values: any[], filterCondition: any) =>
    values.filter((element: any) => filterCondition(element));

  const transArrayData = (data: any, timeGranularityFilter: string) => {
    const DimensionName: any = Array.from(
      new Set(data?.flatMap((element: any) => Object.keys(element))),
    ).find((key: any) => key !== 'values');
    const filterCondition = getFilterCondition(timeGranularityFilter);
    return data?.map((product: any) => ({
      [DimensionName]: product[DimensionName],
      values: filterValues(product.values, filterCondition),
    }));
  };

  const transformMultipleIndicator = (data: any, timeGranularityFilter: string) => {
    const filterCondition = getFilterCondition(timeGranularityFilter);
    return data?.map((metric: any) => ({
      ...metric,
      total: filterValues(metric.total, filterCondition),
    }));
  };

  const transformSingleIndicatorData = (data: any, timeGranularityFilter: string) => {
    const filterCondition = getFilterCondition(timeGranularityFilter);
    return filterValues(data, filterCondition);
  };

  const transformData = (data: any, indicatorName: any) => ({
    name: indicatorName,
    value: data.map((item: any) => ({
      name: item.Time,
      value: item[indicatorName],
    })),
  });

  const fiterGraphData = useMemo(() => {
    if (!graphData || !graphData.data || graphData.data.length === 0) {
      return [];
    }
    const singleIndicatorArray: any = transformSingleIndicatorData(
      graphData?.data[0]?.total,
      timeGranularityFilter,
    );
    setSingleIndicatorNoDimData(singleIndicatorArray);

    const formattedArray = transformMultipleIndicator(graphData?.data, timeGranularityFilter);
    setMultipleIndicatorNoDimData(formattedArray);

    const transformedData = transArrayData(graphData?.data[0].dim_df, timeGranularityFilter);
    setOneDimeensionFilter(transformedData);

    return singleIndicatorArray;
  }, [graphData, timeGranularityFilter]);

  useEffect(() => {
    let filterData = [];
    const colors = graphData?.data?.map((item: any) => item?.metric?.formatting.color);
    if (graphData?.data?.length === 1 && graphData?.data[0].metric?.dimension_name === null) {
      const chartLabelData = graphData?.data?.map((item: any) => item?.metric?.indicator_name);
      const chartDatas = transformData(singleIndicatorNoDimData, chartLabelData[0]);
      const labelsItem = singleIndicatorNoDimData?.map((item: any) => item.Time);
      setLabels(labelsItem);
      setColor(colors);
      setChartData([chartDatas]);
    }
    if (graphData?.data?.length === 1 && graphData?.data[0].metric?.dimension_name) {
      const dimensionName = graphData?.data[0].metric?.dimension_name;
      const labelsItem = oneDimeensionFilter[0].values?.map((item: any) => item.Time);
      setLabels(labelsItem);
      filterData =
        pieDimeFilter?.length > 0
          ? oneDimeensionFilter?.filter((product: any) =>
              pieDimeFilter?.some(
                (item: any) => item.status && item.name === product[dimensionName],
              ),
            )
          : oneDimeensionFilter || [];
      const chartLabelData = graphData?.data?.map((item: any) => item?.metric?.indicator_name);
      const dimColors = graphData?.data?.map((item: any) =>
        item?.metric?.formatting?.dimensionItems?.map((items: any) => items.color),
      );
      const formattedData = filterData?.map((item: any) => ({
        name: `${chartLabelData} (${item[dimensionName]})`,
        value: item.values.map((value: any) => ({
          name: value.Time,
          value: value[chartLabelData[0]],
        })),
      }));
      setChartData(formattedData);
      setColor(dimColors[0]);
    }
    if (graphData?.data?.length > 1 && graphData?.data[0].metric?.dimension_name === null) {
      const labelsItem = multipleIndicatorNoDimData[0]?.total?.map((item: any) => item.Time);
      setLabels(labelsItem);
      const chartDatas = multipleIndicatorNoDimData?.map((metricObject: any) => {
        const name = metricObject?.metric.indicator_name;
        const value = metricObject?.total.map((totalObject: any) => ({
          name: totalObject?.Time,
          value: totalObject[name],
        }));

        return { name, value };
      });
      setColor(colors);
      setChartData(chartDatas);
    }
    if (graphData?.data?.length > 1 && graphData?.data[0].metric?.dimension_name) {
      const dimensionName = graphData?.data[0].metric?.dimension_name;
      filterData =
        pieDimeFilter?.length > 0
          ? graphData?.data[0].dim_df?.filter((product: any) =>
              pieDimeFilter?.some(
                (item: any) => item.status && item.name === product[dimensionName],
              ),
            )
          : graphData?.data[0].dim_df || [];
      const chartLabelData = graphData?.data?.map((item: any) => item?.metric?.indicator_name);
      const dimColors = graphData?.data?.map((item: any) =>
        item?.metric?.formatting?.dimensionItems?.map((items: any) => items.color),
      );
      const formattedData = filterData?.map((item: any) => ({
        name: `${chartLabelData} (${item[dimensionName]})`,
        value: item.values.map((value: any) => ({
          name: value.Time,
          value: value[chartLabelData[0]],
        })),
      }));
      setChartData(formattedData);
      setColor(dimColors[0]);
    }
  }, [graphData, timeGranularityFilter, chartFormatting]);

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const datasets = chartData?.map((entry: any, index: any) => {
    return {
      label: entry.name,
      data: entry.value.map((value: any) => value.value),
      backgroundColor: color[index] || '#d116e9',
      borderColor: CHART_COLORS[index],
    };
  });

  const dataOfChart = {
    labels,
    datasets,
  };
  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart: any) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      };
    },
  };

  const yearFormat = `FY${startDateObject.getFullYear().toString().slice(-2)}`;

  const quarterFormat = `Q${Math.ceil((startDateObject.getMonth() + 1) / 3)}-${String(
    yearFormat,
  ).slice(-2)}`;

  const monthFormat = `${monthNames[startDateObject.getMonth()]}-${String(yearFormat).slice(-2)}`;
  const chartFilter = 'M';
  const targetDate = () => {
    if (chartFilter === 'M') {
      return monthFormat;
    }
    if (chartFilter === 'Q') {
      return quarterFormat;
    }
    return yearFormat;
  };

  const drawBackgroundPlugin = {
    id: 'drawBackground',
    beforeDraw: ({ ctx, chartArea, data, scales }: any) => {
      ctx.fillStyle = 'transparent';

      const targetLabel = targetDate();
      const targetIndex = data.labels.indexOf(`${targetLabel}`);
      if (targetIndex !== -1) {
        // Get the x-axis pixel position for the specified dataset and index
        const xPosition = scales.x.getPixelForValue(data.labels[targetIndex]);

        ctx.fillStyle = '#EEF2F6';
        ctx.fillRect(
          chartArea.left,
          chartArea.top,
          xPosition - chartArea.left,
          chartArea.bottom - chartArea.top,
        );
      } else {
        ctx.fillRect(
          chartArea.left,
          chartArea.top,
          chartArea.right - chartArea.left,
          chartArea.bottom - chartArea.top,
        );
      }
    },
  };

  return (
    <Box minWidth={'100%'} height={'90%'}>
      <ReactChart
        type='bar'
        data={dataOfChart}
        options={getDashboradChartOptions({
          chartFormatting,
          chartType: 'bar',
          isLegend: false,
          aspectRatio: 1,
        })}
        plugins={[legendMargin, drawBackgroundPlugin]}
      />
      <Flex
        height={'10%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems='self-start'
        flexDirection='row'
        pt={0}
      >
        <Flex maxW={'70%'} justifyContent={'center'} width={'70%'} flexWrap={'wrap'}>
          {datasets.map((_data: any, index: number) => (
            <Flex alignItems={'center'} p={'2px'} ml={1} key={index}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                bg={color[index]}
                h='13px'
                w='13px'
                mr='0.1rem'
              ></Box>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h='13px'>
                <Text
                  color='black'
                  fontSize={'13px'}
                  fontFamily={'regular_roboto'}
                  fontWeight={'500'}
                >
                  {_data?.label}
                </Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default BarChart;
