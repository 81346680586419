import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

import { AddDimensionItem } from 'models/requestBody/addDimensionItem.interface';
import { ExportDataValue } from 'models/requestBody/createExportData.interface';
import { CreateGuestUser } from 'models/requestBody/createGuestUser.interface';
import { CreateModelAccess } from 'models/requestBody/createModelAccess.interface';
import { CreateModelCopy } from 'models/requestBody/createModelCopy.interface';
import { ModelShare } from 'models/requestBody/createModelShare.interface';
import { GetBlockOutputsParameters } from 'models/requestBody/getBlockOutputsParameters.interface';
import IndicatorDetailsParameters from 'models/requestBody/getIndicatorDetailsParameters.interface';
import { ModelOutputParameters } from 'models/requestBody/getModelOutputs.interface';
import {
  ChangePasswordRequestBody,
  Login,
  ResetPassword,
  SetPasswordRequestBody,
} from 'models/requestBody/login.interface';
import {
  BulkUpdateIndicatorDataInputRequestBody,
  ModifyIndicatorDataInput,
} from 'models/requestBody/modifyIndicatorDataInput.interface';
import { UpdateDimensionItem } from 'models/requestBody/updateDimensionItem.interface';
import { UpdateIndicatorDataInput } from 'models/requestBody/updateIndicatorDataInput.interface';
import { UpdatePropertyValues } from 'models/requestBody/updatePropertyValues.interface';
import { BlockOutput } from 'models/response/blockOutput.interface';
import { UpdateModelTimeRequest } from 'models/ModelsSlice.interface';
import {
  AddTeamMemberRequest,
  SignUpUser,
  UpdateUserOrgDetailRequest,
  UpdateUserTeamPermissionRequest,
} from 'models/UsersSlice.interface';
import setTokenWithExpiry from 'utils/setTokenWithExpiry';

import breakpoints from './breakpoints';

const { REACT_APP_DOMAIN_URL } = process.env;

class ApiService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({ baseURL: REACT_APP_DOMAIN_URL });
    this.instance.interceptors.request.use(async (config) => {
      const token = localStorage.getItem('BLOX_USER_TOKEN');

      (config.headers as AxiosRequestHeaders).authorization = token as string;
      config.timeout = 60 * 4 * 1000;

      return config;
    });

    this.instance.interceptors.response.use(
      (response) => {
        if (response.headers.token !== 'null') {
          setTokenWithExpiry(response.headers.token);
        } else if (localStorage.getItem('BLOX_USER_TOKEN') === 'null') {
          localStorage.removeItem('BLOX_USER_TOKEN');
        }
        return response;
      },
      (error) => {
        if (
          error.response.data.message === 'Token is invalid !!' &&
          !window.location.pathname.includes('create')
        ) {
          const isSignUpPage = window.location.pathname === '/signup';

          localStorage.removeItem('BLOX_USER_TOKEN');
          localStorage.removeItem('BLOX_USER_TOKEN_EXPIRY');

          window.location.href = !isSignUpPage ? '/' : '/signup';
        }
        return Promise.reject(error);
      },
    );
  }

  // Authentication
  login = ({ request_body }: Login) => this.instance.post(breakpoints.login(), request_body);

  signup = ({ request_body }: any) => {
    return this.instance.post(breakpoints.signup(), request_body);
  };

  create_guest_user = ({ request_body }: CreateGuestUser) =>
    this.instance.post(breakpoints.guest_login(), request_body);

  version = () => this.instance.get(breakpoints.version());

  set_password = ({ request_body }: SetPasswordRequestBody) =>
    this.instance.post(breakpoints.set_password(), request_body);

  change_password = ({ request_body }: ChangePasswordRequestBody) =>
    this.instance.post(breakpoints.change_password(), request_body);

  reset_password = ({ request_body }: ResetPassword) =>
    this.instance.post(breakpoints.reset_password(), request_body);

  // User
  fetch_user = () => this.instance.get(breakpoints.user());

  update_user_details = ({ request_body }: SignUpUser) =>
    this.instance.patch(breakpoints.user(), request_body);

  subscribe_user = ({ request_body }: any) =>
    this.instance.post(breakpoints.subscribe_user(), request_body);

  add_team_member = ({ request_body }: AddTeamMemberRequest) =>
    this.instance.post(breakpoints.add_team_member(), request_body);

  get_user_team_list = () => this.instance.get(breakpoints.get_user_team_list());

  fetch_user_invitations = () => this.instance.get(breakpoints.user_invitation());

  update_user_invitations = ({ request_body }: any) =>
    this.instance.put(breakpoints.user_invitation(), request_body);

  fetch_user_organization_details = () =>
    this.instance.get(breakpoints.fetch_user_organization_details());

  update_user_team_permission = ({ request_body }: UpdateUserTeamPermissionRequest) =>
    this.instance.put(breakpoints.update_user_team_permission(), request_body);

  update_user_organization_details = ({ request_body }: UpdateUserOrgDetailRequest) =>
    this.instance.put(breakpoints.update_user_organization_details(), request_body);

  userInvitationData = (token: any) => this.instance.post(breakpoints.invitation_user(), token);

  fetch_user_plan_detail = () => this.instance.get(breakpoints.fetch_user_plan_detail());

  fetch_user_payment_details = () => this.instance.get(breakpoints.fetch_user_payment_details());

  delete_user = ({ request_body }: any) =>
    this.instance.delete(breakpoints.delete_user(request_body.user_id));

  cancel_user_subscription = ({ request_body }: any) =>
    this.instance.post(breakpoints.cancel_user_subscription(), request_body);

  fetch_subscription_features_limits = () =>
    this.instance.get(breakpoints.fetch_subscription_features_limits());

  get_share_model_users = (modelId: number) => {
    return this.instance.get(breakpoints.share_model_user_permission(modelId));
  };

  put_share_model_user_permission = ({ modelId, request_body }: any) => {
    return this.instance.put(breakpoints.share_model_user_permission(modelId), request_body);
  };

  add_models = (data: any) => this.instance.post(breakpoints.models(), data);

  get_user_models = () => this.instance.get(breakpoints.user_models());

  get_model = (modelId: number) => this.instance.get(breakpoints.model(modelId));

  get_model_formula_list = (modelId: number) =>
    this.instance.get(breakpoints.model_formula_metadata(modelId));

  update_model = ({ model_id, request_body }: any) =>
    this.instance.put(breakpoints.model(model_id), request_body);

  delete_model = (modelId: number) => this.instance.delete(breakpoints.model(modelId));

  get_model_info = (modelId: number) => this.instance.get(breakpoints.model_info(modelId));

  create_model_copy = ({ model_id, request_body }: CreateModelCopy) =>
    this.instance.post(breakpoints.model_copy(model_id), request_body);

  get_model_time = (modelId: number) => this.instance.get(breakpoints.model_time(modelId));

  update_model_time = ({ model_id, request_body }: UpdateModelTimeRequest) =>
    this.instance.put(breakpoints.model_time(model_id), request_body);

  get_model_outputs = (modelId: number, parameters: ModelOutputParameters) =>
    this.instance.get(breakpoints.model_outputs(modelId), parameters);

  add_block = (modelId: number, data: any) =>
    this.instance.post(breakpoints.add_block(modelId), data);

  get_block = (blockId: number) => this.instance.get(breakpoints.block(blockId));

  get_block_output = (blockId: number, parameters: any) =>
    this.instance.get(breakpoints.block_outputs(blockId), parameters);

  delete_block = (blockId: number) => this.instance.delete(breakpoints.block(blockId));

  duplicate_block = (blockId: number) => this.instance.post(breakpoints.duplicate_block(blockId));

  get_model_link = (modelId: number) => this.instance.get(breakpoints.model_link(modelId));

  create_model_share = ({ model_id, request_body }: ModelShare) =>
    this.instance.post(breakpoints.model_share(model_id), request_body);

  create_model_access = ({ request_body }: CreateModelAccess) =>
    this.instance.post(breakpoints.model_access(), request_body);

  getCompareScenario = async (blockId: number, parameters: GetBlockOutputsParameters) => {
    const response = await this.instance.get<Array<BlockOutput>>(
      breakpoints.compareScenario(blockId),
      parameters,
    );

    response.data.map((data: any) => {
      if (String(data.type).toLowerCase() === 'percentage') {
        if (data?.total_values?.length) {
          data.total_values = data.total_values.map((item: any) => {
            item.value *= 100;
            item.value2 *= 100;
            return item;
          });
        }
        if (data?.dim_item_data?.length) {
          data.dim_item_data = data.dim_item_data.map((dimItem: any) => {
            dimItem.value = dimItem.value.map((item: any) => {
              item.value *= 100;
              item.value2 *= 100;
              return item;
            });
            return dimItem;
          });
        }
        data.total *= 100;
      }
      return data;
    });

    return response;
  };

  get_block_outputs = async (blockId: number, parameters: GetBlockOutputsParameters) => {
    const response = await this.instance.get<Array<BlockOutput>>(
      breakpoints.block_outputs(blockId),
      parameters,
    );

    response.data.map((data: any) => {
      if (String(data.type).toLowerCase() === 'percentage') {
        if (data?.total_values?.length) {
          data.total_values = data.total_values.map((item: any) => {
            item.value *= 100;
            return item;
          });
        }
        if (data?.dim_item_data?.length) {
          data.dim_item_data = data.dim_item_data.map((dimItem: any) => {
            dimItem.value = dimItem.value.map((item: any) => {
              item.value *= 100;
              return item;
            });
            return dimItem;
          });
        }
        data.total *= 100;
      }
      return data;
    });
    return response;
  };

  time_filter = async (blockId: number, parameters: any) => {
    const response = await this.instance.get<Array<BlockOutput>>(
      breakpoints.time_filter(blockId),
      parameters,
    );
    return response;
  };

  get_scenarios = (modelId: number) => this.instance.get(breakpoints.get_scenarios(modelId));

  updateBlock = (blockId: number, data: any) => this.instance.put(breakpoints.block(blockId), data);

  updateModel = (modelId: number, data: any) => this.instance.put(breakpoints.model(modelId), data);

  get_block_drivers = (blockId: number, parameters?: any) =>
    this.instance.get(breakpoints.block_drivers(blockId), parameters);

  get_block_values = (blockId: number, parameters?: any) =>
    this.instance.get(breakpoints.block_values(blockId), parameters);

  get_block_data_values = (blockId: number, parameters?: any) =>
    this.instance.get(breakpoints.block_values(blockId), parameters);

  get_block_metadata = (blockId: number, parameters?: any) =>
    this.instance.get(breakpoints.block_metadata(blockId), parameters);

  block_dimension = (blockId: number, parameters?: any) =>
    this.instance.post(breakpoints.block_dimensions(blockId), parameters);

  get_block_dimension = (blockId: number, parameters?: any) =>
    this.instance.get(breakpoints.block_item_dimensions(blockId), parameters);

  reorder_indicator = (indicatorId: number, request_body?: any) =>
    this.instance.patch(breakpoints.indicator_reorder(indicatorId), request_body);

  reorder_blocks = (modelId: number, request_body?: any) =>
    this.instance.patch(breakpoints.reorder_blocks(modelId), request_body);

  create_block_export = ({ block_id, request_body }: ExportDataValue) => {
    return this.instance.post(breakpoints.block_export(block_id), request_body, {
      responseType: 'arraybuffer',
    });
  };

  get_indicator = (indicatorId: number) => this.instance.get(breakpoints.indicator(indicatorId));

  update_indicator = (indicatorId: number, data: any) =>
    this.instance.patch(breakpoints.indicator(indicatorId), data);

  add_indicator = (blockId: number, data: any) =>
    this.instance.post(breakpoints.add_indicator(blockId), data);

  duplicate_indicator = (indicatorId: number, data: any) =>
    this.instance.post(breakpoints.duplicate_indicator(indicatorId), data);

  delete_Indicator = (indicatorId: number) =>
    this.instance.delete(breakpoints.indicator(indicatorId));

  get_indicator_data = (indicatorId: number) =>
    this.instance.get(breakpoints.indicator_data(indicatorId));

  update_indicator_data_input = ({ indicator_id, request_body }: UpdateIndicatorDataInput) =>
    this.instance.put(breakpoints.indicator_data_input(indicator_id), request_body);

  get_indicator_data_input = (indicator_id: number, parameters: any) =>
    this.instance.get(breakpoints.indicator_data_input(indicator_id), parameters);

  patch_indicator_data_input = ({ indicator_id, request_body }: ModifyIndicatorDataInput) =>
    this.instance.patch(breakpoints.indicator_data_input(indicator_id), request_body);

  bulk_update_indicator_data = ({ request_body }: BulkUpdateIndicatorDataInputRequestBody) =>
    this.instance.put(breakpoints.bulk_update_indicator_data(), request_body);

  get_indicator_data_input_display = (indicatorId: number) =>
    this.instance.get(breakpoints.indicator_data_input_display(indicatorId));

  get_indicator_output = (indicatorId: number) =>
    this.instance.get(breakpoints.indicator_output(indicatorId));

  get_indicator_details = (indicatorId: number, parameters: IndicatorDetailsParameters) =>
    this.instance.get(breakpoints.indicator_details(indicatorId), parameters);

  get_dimension_items = (dimensionId: any, parameters?: any) => {
    return this.instance.get(breakpoints.dimension_items(dimensionId), parameters);
  };

  add_dimension_item = ({ dimension_id, request_body }: AddDimensionItem) =>
    this.instance.post(breakpoints.dimension_items(dimension_id), request_body);

  update_dimension_item = ({ item_id, request_body }: UpdateDimensionItem) =>
    this.instance.put(breakpoints.dimension_item(item_id), request_body);

  delete_dimension_item = (itemId: any, parameters?: any) =>
    this.instance.delete(breakpoints.dimension_item(itemId), parameters);

  addModelFromLibrary = (modelId: any, data: any) =>
    this.instance.post(breakpoints.modelCopy(modelId), data);

  get_dimension_property_values = (propertyId: number, parameters?: any) =>
    this.instance.get(breakpoints.dimension_property_values(propertyId), parameters);

  getDimensionProperty = (propertyId: number) =>
    this.instance.get(breakpoints.dimensionProperty(propertyId));

  update_dimension_property = (propertyId: number, data: any) =>
    this.instance.put(breakpoints.dimensionProperty(propertyId), data);

  duplicate_dimension = (dimId: number, data: any) =>
    this.instance.post(breakpoints.duplicatedimension(dimId), data);

  deleteDimensionProperty = (propertyId: number) =>
    this.instance.delete(breakpoints.dimensionProperty(propertyId));

  get_dimension_properties = (propertyId: number) =>
    this.instance.get(breakpoints.dimension_properties(propertyId));

  get_dimension_items_group = (propertyId: number, groupProperty: string, scenarioId?: number) =>
    this.instance.get(breakpoints.dimension_items_group(propertyId), {
      params: {
        group_by: groupProperty,
        scenario_id: scenarioId,
      },
    });

  get_property_values = (item_id: number) =>
    this.instance.get(breakpoints.dimension_item_properties(item_id));

  update_property_values = ({ item_id, request_body }: UpdatePropertyValues) =>
    this.instance.put(breakpoints.dimension_items_group_properties(item_id), request_body);

  update_property_values_group = ({ item_id, request_body }: UpdatePropertyValues) =>
    this.instance.put(breakpoints.dimension_items_group_properties(item_id), request_body);

  addNew_property_values_group = ({ item_id, request_body }: UpdatePropertyValues) =>
    this.instance.post(breakpoints.dimension_items_group_properties(item_id), request_body);

  update_group_item_name = ({ item_id, request_body }: any) =>
    this.instance.put(breakpoints.update_group_item_name(item_id), request_body);

  update_group_dim_name = ({ item_id, request_body }: any) =>
    this.instance.patch(breakpoints.group_dim_name(item_id), request_body);

  delete_group_dim_name = (item_id: any) =>
    this.instance.delete(breakpoints.group_dim_name(item_id));

  delete_group_item_name = (dimId: any, parameters?: any) =>
    this.instance.delete(breakpoints.delete_group_item_name(dimId), parameters);

  getBuilderDimension = (modelId: string) => this.instance.get(breakpoints.dimensions(modelId));

  addBuilderDimension = (modelId: string, data: any) =>
    this.instance.post(breakpoints.dimensions(modelId), data);

  getDimensionProperties = (dimensionId: any, parameters?: any) =>
    this.instance.get(breakpoints.dimensionProperties(dimensionId), parameters);

  updateDimensionProperties = (dimensionId: any, parameters?: any) =>
    this.instance.post(breakpoints.dimensionProperties(dimensionId), parameters);

  getDimensionItemsWithProperties = (dimensionId: any, parameters: any) =>
    this.instance.get(breakpoints.dimensionItemsWithProperties(dimensionId), parameters);

  update_property = ({ item_id, request_body }: any) =>
    this.instance.put(breakpoints.update_property(item_id), request_body);

  additem_property = ({ dimension_id, request_body }: any) =>
    this.instance.post(breakpoints.additem_property(dimension_id), request_body);

  update_property_name = ({ item_id, name }: any) =>
    this.instance.put(breakpoints.update_property_name(item_id), { name });

  update_property_bulk = ({ request_body }: any) =>
    this.instance.put(breakpoints.update_property_bulk(), request_body);

  delete_property = (item_id: any, parameters?: any) =>
    this.instance.delete(breakpoints.delete_property(item_id), parameters);

  delete_multiple_property = (request_body: any) =>
    this.instance.post(breakpoints.delete_multiple_property(), request_body);

  reorder_property = (item_id: any, data: any) =>
    this.instance.patch(breakpoints.dimensionReorder(item_id), data);

  get_dimension_group = (data: any, parameters?: any) =>
    this.instance.get(breakpoints.get_dimension_group(data), parameters);

  deleteScenario = (scenarioId: number) =>
    this.instance.delete(breakpoints.deleteOneScenario(scenarioId));

  renameScenario = (scenarioId: number, data: any) =>
    this.instance.put(breakpoints.renameScenario(scenarioId), data);

  getSingleScenario = (scenarioId: number) =>
    this.instance.delete(breakpoints.singleScenario(scenarioId));

  createScenario = (modelId: number, data: any) =>
    this.instance.post(breakpoints.createNewScenario(modelId), data);

  createDuplicateScenario = (scenarioId: number, data: any) =>
    this.instance.post(breakpoints.duplicateScenario(scenarioId), data);

  editDriverInputsData = (blockId: number, data: any) =>
    this.instance.post(breakpoints.editDriverInput(blockId), data);

  uploadExcelFileData = ({ modelId, request_body }: any) =>
    this.instance.post(breakpoints.uploadExcelFile(modelId), request_body);

  getExcelFiles = (modelId: any) => this.instance.get(breakpoints.uploadExcelFile(modelId));

  dimensionReorderingData = ({ modelId, request_body }: any) =>
    this.instance.patch(breakpoints.dimensionReordering(modelId), request_body);

  getSingleExcelFile = (modelId: number, fileId: number) =>
    this.instance.get(breakpoints.getSingleExcelFileDetails(modelId, fileId));

  deleteSingleExcelFile = (modelId: number, fileId: number) =>
    this.instance.delete(breakpoints.getSingleExcelFileDetails(modelId, fileId));

  editExcelFile = ({ modelId, fileId, request_body }: any) =>
    this.instance.put(breakpoints.getSingleExcelFileDetails(modelId, fileId), request_body);

  allActionData = (dataSourceId: number) =>
    this.instance.get(breakpoints.getAllActionData(dataSourceId));

  createActionData = ({ dataSourceId, request_body }: any) =>
    this.instance.post(breakpoints.getAllActionData(dataSourceId), request_body);

  deleteDataAction = (dataSourceId: number, actionId: number) =>
    this.instance.delete(breakpoints.actionData(dataSourceId, actionId));

  getDataAction = (dataSourceId: number, actionId: number) =>
    this.instance.get(breakpoints.actionData(dataSourceId, actionId));

  editActionData = ({ dataSourceId, actionId, request_body }: any) =>
    this.instance.put(breakpoints.actionData(dataSourceId, actionId), request_body);

  getVersionData = (dataSourceId: number) =>
    this.instance.get(breakpoints.getDataSourceVersion(dataSourceId));

  postVersionFile = ({ dataSourceId, request_body }: any) =>
    this.instance.post(breakpoints.getDataSourceVersion(dataSourceId), request_body);

  editVersionFile = ({ dataSourceId, versionId, request_body }: any) =>
    this.instance.put(breakpoints.dataSourceVersion(dataSourceId, versionId), request_body);

  deleteVersionFile = (dataSourceId: number, versionId: number) =>
    this.instance.delete(breakpoints.dataSourceVersion(dataSourceId, versionId));

  getDataMapping = (dataSourceId: number, actionId: number, parameters: any) =>
    this.instance.get(breakpoints.dataSourceMappings(dataSourceId, actionId), parameters);

  runDataMapping = ({ dataSourceId, actionId, request_body }: any) =>
    this.instance.post(breakpoints.dataSourceMappings(dataSourceId, actionId), request_body);

  duplicateSourceData = (dataSourceId: number) =>
    this.instance.post(breakpoints.duplicateDataSource(dataSourceId));

  duplicateActionData = (dataSourceId: number, actionId: number) =>
    this.instance.post(breakpoints.duplicateActionCopy(dataSourceId, actionId));

  getMetadata = (modelId: number) => this.instance.get(breakpoints.model_metadata(modelId));

  reorder_indicator_data = ({ indicator_id, request_body }: any) => {
    return this.instance.put(breakpoints.indicator_data_reorder(indicator_id), request_body);
  };

  getDashboardBlock = (modelId: number) => this.instance.get(breakpoints.dashboardBlock(modelId));
}

export default new ApiService();
