import { Flex, Switch, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useMemo } from 'react';

import {
  getDashboardChartId,
  handleWidgetDisplayValues,
  getKpiChartData,
  fetchDashboardGraph,
  getChartType,
  handleWidgetShowLabels,
  fetchDashboardChart,
  updateChartInstrument,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface Properties {
  chartDisplayValue?: any;
  isEditable?: any;
}

const ShowLabels = ({ chartDisplayValue, isEditable }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const chartId = useSelector(getDashboardChartId);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const chartType = useSelector(getChartType);
  const pieChartData = useSelector(getKpiChartData);
  const [displayValues, setDisplayValues] = useState(false);

  useMemo(() => {
    if (isEditable && chartDisplayValue) {
      setDisplayValues(chartDisplayValue);
    }
  }, [chartDisplayValue]);

  const handleShowLabelsChange = (value: boolean) => {
    dispatch(handleWidgetShowLabels(value));
    setDisplayValues(value);
    dispatch(handleWidgetDisplayValues(value));
    if (pieChartData) {
      dispatch(
        updateChartInstrument({
          ...pieChartData,
          formatting: {
            ...pieChartData.formatting,
            displayValue: value,
          },
        }),
      ).then(() => {
        dispatch(fetchDashboardChart(chartId));
        if (chartType === 3) {
          dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        }
      });
    }
  };

  return (
    <Flex justifyContent={'space-between'} pr={2} alignItems={'center'} mb={2}>
      <Text color={'black.900'} fontWeight={'600'}>
        Show labels
      </Text>
      <Switch
        isChecked={displayValues}
        ml={4}
        onChange={(event) => handleShowLabelsChange(event.target.checked)}
      ></Switch>
    </Flex>
  );
};

export default ShowLabels;
