import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { DragHandleIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  Button,
  MenuList,
  Spinner,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';

import SearchIcon from 'components/Icons/SearchIcon';
import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import MultiColorIcon from 'components/Icons/MultiColorIcon';
import RemoveIconComponent from 'components/Icons/RemoveIcon';
import {
  handleRemoveTableIndicator,
  getBlocks,
  handleDimItemColor,
  getIndicators,
  getFetchIndicatorStatus,
  handleAddTableIndicator,
  getWidgetData,
  getDashboardWidgetType,
  getChartType,
  fetchBlockIndicator,
  getDimensions,
  handleWidgetsBlock,
  getDashboardChartId,
  addChartMetric,
  fetchDashboardGraph,
  updateChartMetric,
  handleWidgetsIndicator,
  fetchDashboardChart,
  createChartInstrument,
  delteChartMetric,
  getDashboardSelectedScenario,
  getAllChartData,
  getModelDashboardId,
} from 'redux/DashboardSlice';
import { STATUS } from 'projectConstants';
import { AppDispatch } from 'utils/GlobalHelpers';
import { getTimescaleDate } from 'redux/ModelsSlice';
import { convertDate, formateDate } from 'utils/ConvertDate';

interface Block {
  blockId: number;
  blockName: string;
}
interface Properties {
  indicator: any;
  dimItemFilter?: any;
  setDimItemFilter?: any;
}

const IndicatorCard = ({ indicator, dimItemFilter, setDimItemFilter }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const dimensions = useSelector(getDimensions);

  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: indicator?.id || 4324,
  });
  const chartId = useSelector(getDashboardChartId);
  const blocks = useSelector(getBlocks);
  const widget = useSelector(getWidgetData);
  const [myDim, setDims] = useState(dimensions);
  const allChartData: any = useSelector(getAllChartData);

  const getTimeDate = useSelector(getTimescaleDate);
  const colorInputReference = useRef<HTMLInputElement>(null);
  const [hover, setHover] = useState<boolean>(false);
  const blockIndicators = useSelector(getIndicators);
  const widgetData: any = JSON.parse(JSON.stringify(widget));

  const chartType = useSelector(getChartType);
  const widetType = useSelector(getDashboardWidgetType);
  const [blockMenuOpen, setBlockMenuOpen] = useState(false);
  const indicatorFetchStatus = useSelector(getFetchIndicatorStatus);
  const [indicatorMenuOpen, setIndicatorMenuOpen] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [currentColor, setCurrentColor] = useState<string>();
  const [itemData, setItemData] = useState<any>();

  const [searchValue, setSearchValue] = useState('');
  const [searchValueIndicator, setSearchValueIndicator] = useState('');
  const [indicators, setIndicators] = useState(blockIndicators);
  const [blocksData, setBlocksData] = useState(blocks);
  const modelDashboardId = useSelector(getModelDashboardId);

  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };
  const formattedStartDate = convertDate(formateDate(getTimeDate?.startDate));
  const formattedEndDate = convertDate(formateDate(getTimeDate?.endDate));

  const getLayoutData = () => {
    return {
      x_axis: `${(Object.keys(allChartData).length % 2) * 4}`,
      y_axis: `${Math.floor(Object.keys(allChartData).length / 2)}`,
    };
  };
  const createChartPayload = () => {
    const payload: any = {
      ...getLayoutData(),
      name: chartType !== 0 ? 'chart' : 'table',
      width: 4,
      height: chartType === 3 ? 3 : 2,
      dashboard_id: modelDashboardId,
      chart_type:
        chartType === 3 ? 'bar' : chartType === 2 ? 'line' : chartType === 3 ? 'combi' : 'table',
      formatting: {
        stackBar: false,
        displayValue: false,
        decimalPlaces: 0,
        negativeDisplay: 'Minus Sign',
        units: 'auto',
        showActualsPeriod: false,
      },
      position: 0,
      chart_metrics: null,
      chart_filters: [
        {
          name: 'Time',
          operator: 'between',
          value: formattedStartDate,
          second_value: formattedEndDate,
          filter_type: 'primary',
        },
      ],
      chart_sorts: [],
      chart_dimensions: [],
      conditional_formatting: [],
    };
    return payload;
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let index = 0; index < 6; index += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const fetchChart = () => {
    dispatch(
      fetchDashboardGraph({
        chartId,
        scenarioId,
        requestBody: [],
      }),
    );
  };

  const handleRemoveIndicator = (indicatorId: number) => {
    dispatch(handleRemoveTableIndicator(indicatorId));
    dispatch(delteChartMetric(indicatorId)).then(() => {
      fetchChart();
    });
  };

  const dimNames = useMemo(() => {
    const dimName = dimensions?.map((dim: any) => {
      return {
        ...dim,
        id: dim?.dimension_id,
        name: dim?.dimension_name,
      };
    });
    return dimName;
  }, [dimensions]);

  const handleBlockItemClick = (block: Block) => {
    setSelectedBlock(block);
    setIndicatorMenuOpen(true);
    dispatch(fetchBlockIndicator(block.blockId));
  };

  const handleAddIndicator = (indicatorValue: any) => {
    dispatch(handleWidgetsBlock(selectedBlock));
    dispatch(handleWidgetsIndicator(indicatorValue));
    const indicatorIndex = widgetData.tableIndicator?.findIndex(
      (ind: any) => indicator.id === ind.id,
    );
    if (indicatorIndex !== -1) {
      widgetData.tableIndicator[indicatorIndex].indicator_name = indicatorValue.indicator_name;
      widgetData.tableIndicator[indicatorIndex].block_id = selectedBlock?.blockId;
      dispatch(updateChartMetric(widgetData?.tableIndicator[indicatorIndex])).then(() => {
        fetchChart();
      });
      dispatch(handleAddTableIndicator(widgetData.tableIndicator));
    } else {
      const indicatorObject = {
        indicator_name: indicatorValue.indicator_name,
        chart_id: chartId,
        block_id: selectedBlock?.blockId,
        dimension_name: null,
        formatting: {
          color: '#d116e9',
        },
        aggregate_function: 'sum',
        position: widgetData.tableIndicator?.length,
      };
      if (widgetData.tableIndicator?.length >= 1) {
        dispatch(addChartMetric(indicatorObject)).then(() => {
          fetchChart();
        });
      } else {
        // dispatch(handleAddTableIndicator([...widgetData.tableIndicator, indicatorObject]));
        const payload = createChartPayload();
        indicatorObject.position = 0;
        delete indicatorObject.chart_id;
        payload.chart_metrics = [indicatorObject];
        dispatch(createChartInstrument(payload)).then((data) => {
          if (chartType !== 1) {
            dispatch(fetchDashboardChart(data?.payload?.id));
          }
          dispatch(
            fetchDashboardGraph({
              chartId: data?.payload?.id,
              scenarioId,
              requestBody: [],
            }),
          );
        });
      }
    }
  };

  useEffect(() => {
    if (dimensions) setDims(dimensions);
  }, [dimensions]);

  const handleAddDimension = (dim: any, localId: any) => {
    const dimensionItems = myDim
      ?.filter((item: any) => item.dimension_id === dim.dimension_id)
      ?.map((item: any) => item.dimension_items);

    const formateDimensionItems = dimensionItems.map((items: any) =>
      items.map((item: any) => ({
        ...item,
        color: getRandomColor(),
      })),
    );
    const newDimItemFilter = {
      id: '',
      localId,
      dimensionName: dim.dimension_name,
      dimensionId: dim.dimension_id,
      dimensionItems: formateDimensionItems[0],
    };
    // const barMatricPayload = {
    //   indicator_name: indicator.indicator_name,
    //   chart_id: indicator?.chart_id,
    //   block_id: indicator?.block_id,
    //   id: indicator.id,
    //   dimension_name: dim.dimension_name,
    //   formatting: { dimensionItems: formateDimensionItems[0].dimensionItems },
    //   aggregate_function: 'sum',
    // };
    // dispatch(updateChartMetric(barMatricPayload)).then(() => {
    //   dispatch(
    //     fetchDashboardGraph({
    //       chartId: indicator?.chart_id,
    //       scenarioId,
    //       requestBody: [],
    //     }),
    //   );
    // });
    setDimItemFilter(newDimItemFilter);
    const indicatorIndex = widgetData.tableIndicator?.findIndex(
      (ind: any) => indicator.id === ind.id,
    );
    const indicatorPayload = JSON.parse(JSON.stringify(indicator));
    indicatorPayload.dimension_name = dim.name;
    indicatorPayload.formatting = {
      dimensionItems: formateDimensionItems[0],
    };
    widgetData.tableIndicator[indicatorIndex].dimension_name = dim.name;
    dispatch(updateChartMetric(indicatorPayload)).then(() => {
      fetchChart();
    });
    dispatch(handleAddTableIndicator(widgetData.tableIndicator));
  };

  // const hasIndicator = widgetData.tableIndicator.length > 0;
  // const hasDimension = dimNames && dimNames?.length > 1;
  // const disableDimension = () => {
  //   if (dimNames?.length <= 1) {
  //     return true;
  //   }
  //   if (chartType === 2 && widgetData.tableIndicator.length > 1) {
  //     return true;
  //   }
  //   return false;
  // };
  // const BlockRow = (properties: any) => {
  //   const { index, style: itemStyle, data } = properties;
  //   return (
  //     <Flex
  //       alignItems={'center'}
  //       borderRadius={'6px'}
  //       paddingInline={2}
  //       _hover={{ backgroundColor: '#EFF2F6' }}
  //       onClick={() => {
  //         handleBlockItemClick(data[index]);
  //       }}
  //       style={itemStyle}
  //       cursor={'pointer'}
  //     >
  //       <Text>{data[index].blockName}</Text>
  //     </Flex>
  //   );
  // };

  // const IndicatorRow = (properties: any) => {
  //   const { index, style: itemStyle, data } = properties;
  //   return (
  //     <MenuItem
  //       alignItems={'center'}
  //       borderRadius={'6px'}
  //       paddingInline={2}
  //       _hover={{ backgroundColor: '#EFF2F6' }}
  //       onClick={() => {
  //         handleAddIndicator(data[index]);
  //       }}
  //       style={itemStyle}
  //       cursor={'pointer'}
  //     >
  //       <Text>{data[index].indicator_name}</Text>
  //     </MenuItem>
  //   );
  // };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (currentColor && itemData) {
      const formatting = {
        color: currentColor,
      };
      const header = setTimeout(() => {
        const indicatorIndex = widgetData.tableIndicator?.findIndex(
          (ind: any) => indicator.id === ind.id,
        );
        const indicatorPayload = JSON.parse(JSON.stringify(indicator));
        indicatorPayload.formatting = formatting;
        widgetData.tableIndicator[indicatorIndex].indicator_name = itemData.name;
        dispatch(updateChartMetric(indicatorPayload)).then(() => {
          fetchChart();
        });
        dispatch(handleAddTableIndicator(widgetData.tableIndicator));
      }, 1200);
      return () => {
        clearTimeout(header);
      };
    }
  }, [itemData, currentColor]);

  const handleAddColor = (event: any, item: any) => {
    setCurrentColor(event?.target.value);
    setItemData(item);
  };

  useEffect(() => {
    setIndicators(blockIndicators);
  }, [blockIndicators]);

  useEffect(() => {
    setBlocksData(blocksData);
  }, [blocksData]);

  useEffect(() => {
    const getBlock = setTimeout(() => {
      setBlocksData(
        blocks?.filter((item: any) =>
          item.blockName.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      );
    }, 500);
    return () => clearTimeout(getBlock);
  }, [searchValue]);

  useEffect(() => {
    const getData = setTimeout(() => {
      setIndicators(
        blockIndicators?.filter((item: any) =>
          item.indicator_name.toLowerCase().includes(searchValueIndicator.toLowerCase()),
        ),
      );
    }, 500);
    return () => clearTimeout(getData);
  }, [searchValueIndicator]);

  const handleColorIconClick = () => {
    if (colorInputReference.current) {
      colorInputReference.current.click();
    }
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>, items: any) => {
    const itemColor = event.target.value;
    const newDimensionsFilters = dimItemFilter.dimensionItems?.map((dimItem: any) =>
      dimItem.dimension_item_id === items.dimension_item_id
        ? {
            ...dimItem,
            color: itemColor,
          }
        : dimItem,
    );

    const pieMatrixObject = {
      indicator_name: indicator.indicator_name,
      chart_id: indicator?.chart_id,
      block_id: indicator?.block_id,
      id: indicator.id,
      dimension_name: newDimensionsFilters.dimensionName,
      formatting: { dimensionItems: newDimensionsFilters.dimensionItems },
      aggregate_function: 'sum',
    };
    dispatch(updateChartMetric(pieMatrixObject)).then(() => {
      dispatch(
        fetchDashboardGraph({
          chartId: indicator?.chart_id,
          scenarioId,
          requestBody: [],
        }),
      );
    });
    setDimItemFilter(newDimensionsFilters);
    dispatch(handleDimItemColor(newDimensionsFilters));
  };

  const renderMenuItems = (isOpen: any, items: any) => {
    if (!isOpen) return null;

    return (
      <MenuList
        sx={{
          padding: '5px',
          borderRadius: '9px',
          border: '0.719px solid #F5F5F5',
          boxShadow: '0px 0px 18.977px 0px',
        }}
      >
        {items?.dimensionId === 0 ? (
          <MenuItem>Select Dimension</MenuItem>
        ) : (
          items?.dimensionItems?.map((item: any) => (
            <Flex
              key={item?.dimension_item_id}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Tooltip placement='top' label={item?.dimension_item_name}>
                <Flex w={'75%'} mb={1}>
                  {item?.dimension_item_name?.length > 10 ? (
                    <Text>{item?.dimension_item_name.slice(0, 10)}...</Text>
                  ) : (
                    <Text>{item?.dimension_item_name}</Text>
                  )}
                </Flex>
              </Tooltip>
              <Flex alignItems={'center'} w={'25%'}>
                <Flex w={'70%'}>
                  <input
                    type='color'
                    value={item?.color}
                    onChange={(event) => handleColorChange(event, item)}
                    style={{
                      width: '22px',
                      height: '22px',
                    }}
                  />
                </Flex>
                <Flex w={'30%'}>
                  <ChevronDownIcon
                    onClick={handleColorIconClick}
                    color={'black'}
                    style={{ cursor: 'pointer' }}
                  />
                </Flex>
              </Flex>
            </Flex>
          ))
        )}
      </MenuList>
    );
  };

  return (
    <Box alignItems={'center'} ref={setNodeRef} style={style}>
      {indicator && (
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Flex alignItems={'center'}>
            <DragHandleIcon
              visibility={hover ? 'visible' : 'hidden'}
              {...listeners}
              {...attributes}
              cursor={'grab'}
              color={'#959595'}
              _focus={{ outline: 'none' }}
              height={'13px'}
            />
            <Flex gap={2}>
              <Menu
                isOpen={blockMenuOpen}
                closeOnSelect={true}
                strategy={'fixed'}
                placement='bottom-start'
                onClose={() => setBlockMenuOpen(!blockMenuOpen)}
              >
                <MenuButton onClick={() => setBlockMenuOpen(true)}>
                  {indicator.indicator_name}
                </MenuButton>
                <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
                  <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents='none'
                        h='100%'
                        children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                      />
                      <Input
                        width={'93%'}
                        color={'black.900'}
                        _placeholder={{ color: '#D9D9D9' }}
                        // ref={inputReference}
                        placeholder='Search'
                        onChange={(event: any) => {
                          setSearchValue(event.target.value);
                        }}
                      />
                    </InputGroup>
                  </Flex>
                  {blocksData?.length !== 0 ? (
                    <Box className='scroll' overflow='scroll' maxHeight={'150px'}>
                      {blocksData?.map((data: any) => (
                        <Flex
                          alignItems={'center'}
                          borderRadius={'6px'}
                          padding={1}
                          _hover={{ backgroundColor: '#EFF2F6' }}
                          onClick={() => {
                            handleBlockItemClick(data);
                          }}
                          // style={itemStyle}
                          cursor={'pointer'}
                        >
                          <Text>{data.blockName}</Text>
                        </Flex>
                      ))}
                    </Box>
                  ) : (
                    <Text>No Block Found</Text>
                  )}
                </MenuList>
              </Menu>
            </Flex>
          </Flex>

          <Flex justifyContent={'space-around'}>
            <Box>
              {/* Indicator menu */}
              <Menu
                isOpen={indicatorMenuOpen}
                strategy={'fixed'}
                placement='bottom-start'
                offset={[0, 0]}
                closeOnSelect={true}
                onClose={() => setIndicatorMenuOpen(!indicatorMenuOpen)}
              >
                <MenuButton></MenuButton>
                {indicatorFetchStatus === STATUS.LOADING ? (
                  <MenuList>
                    <MenuItem>
                      <Spinner />
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
                    <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          h='100%'
                          children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                        />
                        <Input
                          width={'93%'}
                          color={'black.900'}
                          _placeholder={{ color: '#D9D9D9' }}
                          // ref={inputReference}
                          placeholder='Search'
                          onChange={(event: any) => {
                            setSearchValueIndicator(event.target.value);
                          }}
                        />
                      </InputGroup>
                    </Flex>
                    {blockIndicators?.length > 1 ? (
                      <Box className='scroll' overflow='scroll' maxHeight={'150px'}>
                        {indicators?.map((indicatorData: any) => (
                          <Flex
                            alignItems={'center'}
                            borderRadius={'6px'}
                            paddingInline={2}
                            _hover={{ backgroundColor: '#EFF2F6' }}
                            onClick={() => handleAddIndicator(indicatorData)}
                            // style={itemStyle}
                            cursor={'pointer'}
                          >
                            <Text>{indicatorData.indicator_name}</Text>
                          </Flex>
                        ))}
                      </Box>
                    ) : (
                      <MenuItem>No Indicator Found</MenuItem>
                    )}
                  </MenuList>
                )}
              </Menu>
            </Box>

            {/* dimension item menu */}
            <Menu strategy='fixed'>
              <MenuButton
              //  disabled={disableDimension()}
              >
                <Flex alignItems='center' mr='5px'>
                  <DimensionTabIcon
                    //  color={disableDimension() ? 'gray' : '#4BB6B9'}
                    color={'#4BB6B9'}
                  />
                  <Text
                    // color={disableDimension() ? 'gray' : '#4BB6B9'}
                    color={'#4BB6B9'}
                  >
                    {/* {indicator.dimension_name || (hasIndicator ? (!disableDimension() ? '+ add' : '(0)') : '+ add')} */}
                    {indicator.dimension_name || '+ add'}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList>
                {dimNames?.map((dim: any) =>
                  dim?.name === 'Time' ? null : (
                    <MenuItem
                      // isDisabled={!hasDimension}
                      key={dim?.name}
                      onClick={() => handleAddDimension(dim, dimItemFilter?.localId)}
                    >
                      {/* {!hasDimension ? 'No Dimensions Available' : dim.name} */}
                      {dim?.name}
                    </MenuItem>
                  ),
                )}
              </MenuList>
            </Menu>

            {/* Dimension Color menu for chart */}

            {widetType === 2 && indicator.dimension_name === null && (
              <Menu strategy='fixed'>
                <Box w={'24px'} h={'24px'}>
                  <input
                    onChange={(event: any) => handleAddColor(event, indicator)}
                    type='color'
                    value={indicator?.formatting?.color}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Box>
              </Menu>
            )}

            {widetType === 2 &&
              indicator?.dimension_name !== null &&
              dimItemFilter?.dimensionItems.length > 0 && (
                <Flex>
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton width={'60px'} m={0} p={0} as={Button} border={'none'}>
                          <Flex justifyContent={'flex-start'} alignItems={'center'}>
                            <Box>
                              <MultiColorIcon />
                            </Box>
                            <Box>
                              <ChevronDownIcon
                                style={{ cursor: 'pointer' }}
                                color={'black'}
                                height={'16px'}
                                width={'16px'}
                              />
                            </Box>
                          </Flex>
                        </MenuButton>
                        {renderMenuItems(isOpen, dimItemFilter)}
                      </>
                    )}
                  </Menu>
                </Flex>
              )}

            <Flex alignItems={'center'} mx={2}>
              <RemoveIconComponent onClick={() => handleRemoveIndicator(indicator.id)} />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default IndicatorCard;
