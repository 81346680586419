interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginRight?: string;
}

const ComboChartIcon = ({
  width = 18,
  height = 18,
  color = 'white.900',
  marginRight,
}: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      color={color}
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M5 14C5.28333 14 5.521 13.904 5.713 13.712C5.90433 13.5207 6 13.2833 6 13V8C6 7.71667 5.90433 7.479 5.713 7.287C5.521 7.09567 5.28333 7 5 7C4.71667 7 4.479 7.09567 4.287 7.287C4.09567 7.479 4 7.71667 4 8V13C4 13.2833 4.09567 13.5207 4.287 13.712C4.479 13.904 4.71667 14 5 14ZM9 14C9.28333 14 9.521 13.904 9.713 13.712C9.90433 13.5207 10 13.2833 10 13V5C10 4.71667 9.90433 4.479 9.713 4.287C9.521 4.09567 9.28333 4 9 4C8.71667 4 8.47933 4.09567 8.288 4.287C8.096 4.479 8 4.71667 8 5V13C8 13.2833 8.096 13.5207 8.288 13.712C8.47933 13.904 8.71667 14 9 14ZM13 14C13.2833 14 13.5207 13.904 13.712 13.712C13.904 13.5207 14 13.2833 14 13V11C14 10.7167 13.904 10.479 13.712 10.287C13.5207 10.0957 13.2833 10 13 10C12.7167 10 12.4793 10.0957 12.288 10.287C12.096 10.479 12 10.7167 12 11V13C12 13.2833 12.096 13.5207 12.288 13.712C12.4793 13.904 12.7167 14 13 14ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H10C10.2833 0 10.521 0.0956666 10.713 0.287C10.9043 0.479 11 0.716667 11 1C11 1.28333 10.9043 1.52067 10.713 1.712C10.521 1.904 10.2833 2 10 2H2V16H16V8C16 7.71667 16.096 7.479 16.288 7.287C16.4793 7.09567 16.7167 7 17 7C17.2833 7 17.5207 7.09567 17.712 7.287C17.904 7.479 18 7.71667 18 8V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM15 6C14.7167 6 14.4793 5.904 14.288 5.712C14.096 5.52067 14 5.28333 14 5V4H13C12.7167 4 12.4793 3.90433 12.288 3.713C12.096 3.521 12 3.28333 12 3C12 2.71667 12.096 2.479 12.288 2.287C12.4793 2.09567 12.7167 2 13 2H14V1C14 0.716667 14.096 0.479 14.288 0.287C14.4793 0.0956666 14.7167 0 15 0C15.2833 0 15.5207 0.0956666 15.712 0.287C15.904 0.479 16 0.716667 16 1V2H17C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3C18 3.28333 17.904 3.521 17.712 3.713C17.5207 3.90433 17.2833 4 17 4H16V5C16 5.28333 15.904 5.52067 15.712 5.712C15.5207 5.904 15.2833 6 15 6Z'
        fill='#787878'
      />
    </svg>
  );
};

export default ComboChartIcon;
