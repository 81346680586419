import { Flex, Switch, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getWidgetData,
  fetchDashboardChart,
  getKpiChartData,
  updateChartInstrument,
  getDashboardChartId,
  handleWidgetSparkLine,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface DashboardModalSparkLineProperties {
  chartSparkline?: any;
  isEditable?: any;
}

const DashboardModalSparkLine = ({ chartSparkline }: DashboardModalSparkLineProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const widgetData = useSelector(getWidgetData);
  const chartId = useSelector(getDashboardChartId);
  const kpiChartData = useSelector(getKpiChartData);
  const [sparkLine, setSparkLine] = useState(false);

  useMemo(() => {
    if (chartSparkline) {
      setSparkLine(chartSparkline);
    }
  }, [chartSparkline]);

  useMemo(() => {
    if (widgetData.sparkLine) {
      setSparkLine(widgetData.sparkLine);
    }
  }, [widgetData.sparkLine]);

  const timeFilterOperator = useMemo(() => {
    const timeFilter = kpiChartData?.chart_filters?.find(
      (item: any) => item.filter_type === 'primary',
    ).operator;
    return timeFilter;
  }, [chartSparkline, kpiChartData]);

  const chartPayload = useMemo(() => {
    if (kpiChartData) {
      const payload = kpiChartData;
      return payload;
    }
    return null;
  }, [kpiChartData]);

  const handleSparkLineChange = (value: boolean) => {
    setSparkLine(value);
    dispatch(handleWidgetSparkLine(value));
    dispatch(
      updateChartInstrument({
        ...chartPayload,
        formatting: {
          ...chartPayload.formatting,
          sparkline: value,
        },
      }),
    ).then(() => {
      dispatch(fetchDashboardChart(chartId));
    });
  };

  return (
    <Flex alignItems={'center'} mb={2}>
      <Text color={'black.900'} fontWeight={'600'}>
        Spark line
      </Text>
      <Switch
        isDisabled={timeFilterOperator === 'eq'}
        isChecked={sparkLine}
        ml={4}
        onChange={(event) => handleSparkLineChange(event.target.checked)}
      ></Switch>
    </Flex>
  );
};

export default DashboardModalSparkLine;
