/* eslint-disable jsx-a11y/aria-proptypes */
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useSelector, useDispatch } from 'react-redux';

import { getWidgetData, handleWidgetDisplayMarkers } from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const DisplayMarkers = () => {
  const dispatch: AppDispatch = useDispatch();

  const widgetData = useSelector(getWidgetData);

  const handleDisplayMakersChange = (value: boolean) => {
    dispatch(handleWidgetDisplayMarkers(value));
  };

  return (
    <Flex justifyContent={'space-between'} pr={2} alignItems={'center'} h={8}>
      <Flex>
        <Text color={'black.900'} fontWeight={'600'}>
          Display markers
        </Text>
      </Flex>
      <Flex>
        {widgetData.displayMarkers && (
          <>
            <Flex
              minWidth={'80px'}
              width={'100%'}
              gap={2}
              bg='#F9F9F9'
              border={'1px solid #D9D9D9'}
              p={'3px 5px'}
              borderRadius='4px'
            >
              <RangeSlider aria-label={['min', 'max']}>
                <RangeSliderTrack sx={{ bg: 'black', height: '1px' }}>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} sx={{ bg: 'black', boxSize: '10px' }} />
              </RangeSlider>
              <GoChevronDown size={22} />
            </Flex>
          </>
        )}
      </Flex>
      <Flex>
        <Box alignItems={'center'}>
          <Switch
            isChecked={widgetData.displayMarkers}
            onChange={(event) => handleDisplayMakersChange(event.target.checked)}
          ></Switch>
        </Box>
      </Flex>
    </Flex>
  );
};

export default DisplayMarkers;
