import React, { useState } from 'react';
import { Box, Menu, MenuButton, MenuList, MenuItem, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DragHandleIcon } from '@chakra-ui/icons';

import Pensil from 'components/Icons/Pencil';
import InfoIconComponent from 'components/Icons/InfoIcon';
import {
  handleIsEditModal,
  fetchDashboardGraph,
  handleAddCurrentModalChart,
  handleWidgetType,
  handleChartType,
  fetchDashboardChart,
  // getAllChartData,
  handleDeleteDashboardChart,
  deleteChart,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import KPICard from './kpi/KpiCard';
import DashboardTable from './table/DashboardTable';
import ChartCard from './chart/ChartCard';
// import KPICard from './kpi/KpiCard';

interface Properties {
  widget: any;
}
const LayoutComponent = ({ widget }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  // const allChartData = useSelector(getAllChartData);
  const [hover, setHover] = useState<boolean>(false);

  const handleEditClick = (chartType: string, chartId: string) => {
    dispatch(handleAddCurrentModalChart(chartId));
    dispatch(handleIsEditModal(true));
    switch (chartType) {
      case 'kpi':
        dispatch(handleWidgetType(1));
        dispatch(fetchDashboardChart(chartId));
        dispatch(handleIsEditModal(true));
        break;
      case 'pie':
        dispatch(handleIsEditModal(true));
        dispatch(handleWidgetType(2));
        dispatch(handleChartType(1));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        dispatch(fetchDashboardChart(chartId));
        break;
      case 'bar':
        dispatch(handleIsEditModal(true));
        dispatch(handleWidgetType(2));
        dispatch(handleChartType(3));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        dispatch(fetchDashboardChart(chartId));
        break;
      case 'table':
        dispatch(handleWidgetType(3));
        dispatch(
          fetchDashboardGraph({
            chartId,
            scenarioId,
            requestBody: [],
          }),
        );
        dispatch(fetchDashboardChart(chartId));
        break;
      default:
        break;
    }
  };

  const handleDelete = (id: string) => {
    dispatch(deleteChart({ chartId: id })).then(() => {
      dispatch(handleDeleteDashboardChart(id));
    });
  };
  return (
    <Box
      padding={1}
      position={'relative'}
      zIndex={'1'}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Flex visibility={hover ? 'visible' : 'hidden'} justifyContent={'space-between'} mb={1}>
        <button className='dashboard-drag-header'>
          <DragHandleIcon />
        </button>

        <Flex gap={1} alignItems={'center'}>
          <Box cursor={'pointer'} onClick={() => handleEditClick(widget.chart_type, widget.id)}>
            <Pensil />
          </Box>
          <Box>
            <Menu closeOnSelect={true} strategy='fixed' variant={'primary'}>
              <MenuButton>
                <InfoIconComponent />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleEditClick(widget.chart_type, widget.id)}>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => handleDelete(widget.id)}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Flex>

      <Box>
        {widget.chart_type === 'kpi' && <KPICard chartData={widget} isDashboardLayout={true} />}
        {widget.chart_type === 'pie' && <ChartCard chartData={widget} isDashboardLayout={true} />}
        {widget.chart_type === 'bar' && <ChartCard chartData={widget} isDashboardLayout={true} />}
        {widget.chart_type === 'table' && (
          <DashboardTable
            tableData={widget.tableData}
            tableHeaderData={widget.tableHeaderData}
            expandkey={widget.expandKey}
            tableFilter={widget.tableFilter}
            isDashboardLayout={true}
            chartId={widget.id}
          />
        )}
      </Box>
    </Box>
  );
};

export default LayoutComponent;
