import { Flex, Box, Text, Input } from '@chakra-ui/react';

const LeftAxes = () => {
  return (
    <>
      <Box>
        <Flex alignItems={'center'} mb={2}>
          <Text color={'black.900'} fontWeight={'600'}>
            Left Axes
          </Text>
        </Flex>
        <Flex>
          <Text size={'Roboto-14'} color={'#787878'} width={'50%'}>
            Min
          </Text>
          <Text size={'Roboto-14'} color={'#787878'} width={'50%'}>
            Max
          </Text>
        </Flex>
        <Flex>
          <Box>
            <Input w='60%' bg='#F9F9F9' value={'Auto'} borderRadius='6px' height='8' />
          </Box>
          <Box>
            <Input w='60%' bg='#F9F9F9' value={'Auto'} borderRadius='6px' height='8' />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default LeftAxes;
