import { Button, Flex } from '@chakra-ui/react';

interface Properties {
  handleCreate: () => void;
}

const DashboardModalButtons = ({ handleCreate }: Properties) => {
  return (
    <Flex bgColor={'white'} justifyContent={'end'} minH={'45px'} p={'0 1rem 1rem 0'} gap={4}>
      <Button variant={'save_button'} onClick={handleCreate}>
        Done
      </Button>
    </Flex>
  );
};

export default DashboardModalButtons;
