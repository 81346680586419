import React, { useEffect, useState } from 'react';
import '../../index.css';
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import Plus from 'components/Icons/Plus';
import {
  getWidgetData,
  handleAddTableIndicator,
  getChartType,
  getTableIndicator,
  reorderChart,
  fetchDashboardGraph,
  getDashboardSelectedScenario,
  getDashboardChartId,
} from 'redux/DashboardSlice';
import { getBlocks } from 'redux/ModelsSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import IndicatorCard from './IndicatorCard';

const DashboardTableIndicator = () => {
  const widgetData = useSelector(getWidgetData);
  const modelData = useSelector(getBlocks);
  const tableIndicator = useSelector(getTableIndicator);
  const chartType = useSelector(getChartType);
  const [selectedDimFilter, setSelectedDimFilter] = useState<any>([]);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const chartId = useSelector(getDashboardChartId);

  const [indicators, setIndicators] = useState<any>(widgetData.tableIndicator);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const indicatorIds = indicators.map((indicator: any) => indicator.id);
    const oldIndex = indicatorIds.indexOf(active.id);
    const newIndex = indicatorIds.indexOf(over.id);

    const activeIndicator = indicators.find((ind: any) => ind.id === active.id);
    const overIndicator = indicators.find((ind: any) => ind.id === over.id);

    dispatch(
      reorderChart({
        chart_metrics: [
          {
            id: active.id,
            position: overIndicator?.position,
          },
          {
            id: over.id,
            position: activeIndicator?.position,
          },
        ],
      }),
    ).then(() => {
      dispatch(
        fetchDashboardGraph({
          chartId,
          scenarioId,
          requestBody: [],
        }),
      );
    });
    dispatch(handleAddTableIndicator([...arrayMove(indicators, oldIndex, newIndex)]));
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handlesetDimItems = (data: any) => {
    const filteData = selectedDimFilter.map((item: any) => {
      if (data.localId === item.localId) {
        return {
          ...item,
          dimensionName: data.dimensionName,
          dimensionId: data.dimensionId,
          dimensionItems: data.dimensionItems,
        };
      }
      return item;
    });
    setSelectedDimFilter(filteData);
  };

  const handleAddIndicator = () => {
    setSelectedDimFilter([
      ...selectedDimFilter,
      {
        localId: selectedDimFilter.length + 1,
        id: '',
        dimensionId: 0,
        dimensionItems: [],
        dimensionName: 'Dimension',
      },
    ]);
    setIndicators([
      ...indicators,
      {
        id: indicators.length + 1,
        indicator_name: 'Select Block',
        aggregate_function: 'sum',
        formatting: null,
        block_id: null,
        dimension_name: null,
      },
    ]);
  };
  const disableDimension = () => {
    if (modelData?.dimensions.length <= 1) {
      return { disable: true, message: 'No dimensions available for this indicator' };
    }
    if (chartType === 2 && widgetData.tableIndicator.length > 1) {
      return {
        disable: true,
        message: 'Dimensions not available when displaying multiple indicators',
      };
    }
    return { disable: false, message: '' };
  };

  useEffect(() => {
    setIndicators(widgetData.tableIndicator);
  }, [widgetData]);

  useEffect(() => {
    const selectedIndicators = tableIndicator?.data?.map((indicator: any) => {
      return indicator.metric;
    });
    if (selectedIndicators) {
      dispatch(handleAddTableIndicator(selectedIndicators));
    }
  }, [tableIndicator]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    <Box>
      <Flex alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Text color={'black.900'} fontWeight={'600'}>
          Indicators
        </Text>
        <Box>
          <Button
            width={'14.583px'}
            disabled={indicators.length > widgetData.tableIndicator.length}
            onClick={handleAddIndicator}
          >
            <Plus color='#787878' />
          </Button>
        </Box>
      </Flex>
      <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Text color={'black.200'}>Indicators</Text>
        {widgetData.tableIndicator.length > 0 ? (
          <>
            {!disableDimension().disable ? (
              <Text mr={5} color={'black.200'}>
                Dimensions
              </Text>
            ) : (
              <Flex justifyContent={'center'} p={2} borderRadius={5} backgroundColor={'#F4F3FF'}>
                <Text alignItems={'center'} fontSize={'smaller'} color={'black'}>
                  {disableDimension().message}
                </Text>
              </Flex>
            )}
          </>
        ) : (
          <Text mr={5} color={'black.200'}>
            Dimensions
          </Text>
        )}
      </Flex>
      {indicators.length > 0 && (
        <Box maxH={'100%'} overflow={'scroll'} maxW={'100%'} className='scroll'>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={indicators} strategy={verticalListSortingStrategy}>
              {indicators?.map((indicator: any, index: any) => (
                <Box key={indicator?.id} margin={'2'}>
                  <IndicatorCard
                    key={indicator?.id}
                    setDimItemFilter={handlesetDimItems}
                    dimItemFilter={selectedDimFilter[index]}
                    indicator={indicator}
                  />
                </Box>
              ))}
            </SortableContext>
          </DndContext>
        </Box>
      )}
    </Box>
  );
};

export default DashboardTableIndicator;
