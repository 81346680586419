/* eslint-disable no-lonely-if */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { DimItem } from 'models/dimensionItem.interface';
import { NewTotalValues } from 'models/response/blockOutput.interface';
import { STATUS } from 'projectConstants';
import apiService from 'services';

export const fetchModel = createAsyncThunk('model/fetch', async (modelId: number, thunkAPI) => {
  try {
    const response = await apiService.get_model(modelId);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const FetchPlangPageIndicatorTableData = createAsyncThunk(
  'FetchPlangPageIndicatorTableData/get',
  async ({ indicator_id, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_indicator_data_input(indicator_id, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const updateIndicatorData = createAsyncThunk(
  'updateIndicatorData/put',
  async ({ indicator_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.reorder_indicator_data({ indicator_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const UpdatePlangPageIndicatorTableData = createAsyncThunk(
  'UpdatePlangPageIndicatorTableData/update',
  async ({ indicator_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_indicator_data_input({ indicator_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchBlock = createAsyncThunk('block/fetch', async (blockId: number, thunkAPI) => {
  try {
    const response = await apiService.get_block(blockId);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const fetchBlockMetadata = createAsyncThunk(
  'block_metadata/fetch',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_metadata(blockId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchBlockDrivers = createAsyncThunk(
  'block_drivers/fetch',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_drivers(blockId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchAnalysisSectionData = createAsyncThunk(
  'chart_data/fetch',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_outputs(blockId, parameters);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchTableData = createAsyncThunk(
  'table_data/fetch',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_outputs(blockId, parameters);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchBlockItemDimensions = createAsyncThunk(
  'blockItemDimension/get',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_dimension(blockId, parameters);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateTimeFilter = createAsyncThunk(
  'blockOutputs/timeFilter',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.time_filter(blockId, parameters);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const formatDataOnTimeScale = (state: any, output: any) => {
  const returnData = [];
  const basicData = output?.length > 0 ? output[0].total_values : [];
  if (basicData && state.planPageStartDate && state.planPageEndDate) {
    const start = basicData.findIndex((element: any) => element.name === state.planPageStartDate);
    const end = basicData.findIndex((element: any) => element.name === state.planPageEndDate);
    // eslint-disable-next-line unicorn/no-for-loop
    for (let index = 0; index < output.length; index += 1) {
      const data = output[index];
      if (data?.total_values && data?.total_values.length) {
        const slicedData = data?.total_values.slice(start, end + 1);
        data.total_values = slicedData;
      }
      if (data?.dim_item_data && data?.dim_item_data.length) {
        const items: NewTotalValues[] = [];
        for (let dimIndex = 0; dimIndex < data?.dim_item_data?.length; dimIndex += 1) {
          const dimItem = data?.dim_item_data[dimIndex];
          const slicedData = (dimItem.value as NewTotalValues[]).slice(start, end + 1);
          dimItem.value = slicedData;
          items.push(dimItem);
        }
        data.dim_item_data = items;
      }
      returnData.push(data);
    }
  } else {
    return output;
  }
  return returnData;
};

export const formatChartData = (state: any, slice: boolean) => {
  const chartOutput = JSON.parse(JSON.stringify(state.chartOutput));
  if (state.chartOutput) {
    if (chartOutput && chartOutput.length > 0) {
      const tableDataArray = [];
      const maxValue =
        state.maxValue > Object.keys(chartOutput[0].total_values).length
          ? Object.keys(chartOutput[0].total_values).length - 1
          : state.maxValue;
      state.maxValue = maxValue;
      for (let index = 0; index < chartOutput?.length; index += 1) {
        const data = chartOutput[index];
        if (data?.dim_item_data && data?.dim_item_data.length > 0) {
          const items: NewTotalValues[] = (data.dim_item_data as NewTotalValues[]).map(
            (item: any) => {
              if (item.value) {
                const itemKeys: any = [];
                (item.value as NewTotalValues[])?.map((subItem: any) => {
                  let isShow = false;
                  if (state.chartFilter === 'Q' && subItem.name[0] === 'Q') {
                    isShow = true;
                  } else if (state.chartFilter === 'Y' && subItem.name.length === 4) {
                    isShow = true;
                  } else if (subItem.name.length === 6 && state.chartFilter === 'M') {
                    isShow = true;
                  }

                  if (isShow) itemKeys.push(subItem);
                  return subItem;
                });
                item.value = slice ? itemKeys.slice(state.minValue, state.maxValue + 1) : itemKeys;
              }

              return item;
            },
          );
          data.dim_item_data = items;
        }

        if (data?.total_values && data?.total_values.length) {
          const valueItems: Array<any> = [];
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          (data.total_values as NewTotalValues[]).map((item: any) => {
            let isShow = false;

            if (state.chartFilter === 'Q' && item.name[0] === 'Q') {
              isShow = true;
            } else if (state.chartFilter === 'Y' && item.name.length === 4) {
              isShow = true;
            } else if (item.name.length === 6 && state.chartFilter === 'M') {
              isShow = true;
            }
            if (isShow) {
              valueItems.push(item);
            }
            return item;
          });
          state.sliderValues = valueItems.map((object: any) => object.name);
          data.total_values = slice
            ? valueItems.slice(state.minValue, state.maxValue + 1)
            : valueItems;
        }

        tableDataArray.push(data);
      }
      state.tabsData = tableDataArray;

      if (slice) {
        state.chartLabels = state.sliderValues.slice(state.minValue, state.maxValue + 1);
      } else {
        state.chartLabels = state.sliderValues;
        state.minValue = 0;
        state.maxValue = state.sliderValues.length - 1;
      }
    } else {
      state.tabsData = [];
    }
  }
};

export const formatTableData = (state: any) => {
  // state.planPageStartDate = action.payload.start_date;
  // state.planPageEndDate = action.payload.end_date;
  if (state.indicatorTableOutput) {
    const indicatorTableOutput = JSON.parse(JSON.stringify(state.indicatorTableOutput));
    let keysToAdd: string[] = [];
    if (indicatorTableOutput && indicatorTableOutput.length > 0) {
      if (state.expandKey) {
        if (state.tableFilter === 'Y') {
          const year = state.expandKey;
          for (
            let index = 0;
            index < Object.values(indicatorTableOutput[0].time_granularity[year]).length;
            index += 1
          ) {
            const quarter: any = Object.values(indicatorTableOutput[0].time_granularity[year])[
              index
            ];
            keysToAdd = keysToAdd.concat(quarter);
          }
        } else if (state.tableFilter === 'Q') {
          const year = state.expandKey.split('-')[1];
          const quarter: string = state.expandKey;

          keysToAdd = indicatorTableOutput[0].time_granularity[year][quarter].map(
            (month: string) => {
              return `${month}`;
            },
          );
        }
      }
      const tableDataArray = [];
      for (let index = 0; index < indicatorTableOutput?.length; index += 1) {
        const data = indicatorTableOutput[index];
        let filteredkeys: any = [];
        if (data?.dim_item_data && data?.dim_item_data.length > 0) {
          const items: NewTotalValues[] = (data.dim_item_data as NewTotalValues[]).map(
            (item: any) => {
              if (item.value) {
                const itemKeys = (item.value as NewTotalValues[])?.map((subItem: any) => {
                  let isShow = false;
                  let isExpandedColumn = false;
                  if (state.tableFilter === 'Q' && subItem.name[0] === 'Q') {
                    isShow = true;
                  } else if (state.tableFilter === 'Y' && subItem.name.length === 4) {
                    isShow = true;
                  } else if (subItem.name.length === 6 && state.tableFilter === 'M') {
                    isShow = true;
                  } else {
                    const check = keysToAdd.includes(subItem.name);
                    isShow = keysToAdd && keysToAdd.length > 0 && check;
                    if (isShow) isExpandedColumn = true;
                  }

                  Object.assign(subItem, { isShow, isExpandedColumn });

                  return subItem;
                });
                item.value = itemKeys;
              }
              return item;
            },
          );
          data.dim_item_data = items;
        }
        const timeAggregation = state.modelDetail?.time_properties?.time_granularity
          ? (state.modelDetail?.time_properties?.time_granularity as String).toUpperCase()
          : 'M';
        if (data?.total_values && data?.total_values.length) {
          filteredkeys = (data.total_values as NewTotalValues[]).map((item: any) => {
            let isShow = false;
            let isExpandedColumn = false;
            let isShowExpandable = false;
            if (state.tableFilter === 'Q' && item.name[0] === 'Q') {
              isShow = true;
              isShowExpandable = true;
            } else if (state.tableFilter === 'Y' && item.name.length === 4) {
              isShow = true;
              isShowExpandable = true;
            } else if (item.name.length === 6 && state.tableFilter === 'M') {
              isShow = true;
            } else {
              const check = keysToAdd.includes(item.name);
              isShow = keysToAdd && keysToAdd.length > 0 && check;
              if (isShow) isExpandedColumn = true;
            }

            if (timeAggregation === 'Y') isShowExpandable = false;

            Object.assign(item, { isShow, isShowExpandable, isExpandedColumn });
            return item;
          });
        }

        data.total_values = filteredkeys;
        if (index === 0) {
          state.tableHeaderData = filteredkeys;
        }
        tableDataArray.push(data);
      }
      state.tableData = tableDataArray;
    } else {
      state.tableHeaderData = null;
      state.tableData = null;
    }
  }
};

function getClosestToTargetItemCountDimension(data: any) {
  const closestItem = data.reduce((closest: any, item: any) => {
    if (item.dim_item_count <= 15 && (!closest || item.dim_item_count > closest.dim_item_count)) {
      return item;
    }
    return closest;
  }, null);
  return closestItem ? closestItem.id : 'Total';
}

const initialState: any = {
  modelDetail: null,
  modelStatus: STATUS.IDLE,
  blockDetail: null,
  blockStatus: STATUS.IDLE,
  drivers: [],
  driversStatus: STATUS.IDLE,
  metaData: null,
  metaDataStatus: STATUS.IDLE,
  selectedDriver: null,
  showPlanPage: true,
  showInputPage: false,
  showSidePanel: true,
  showAuthModel: false,
  chartInnerWidth: 0,
  dimension: null,

  tableFilter: null,
  chartFilter: null,

  overallIndicatorFilter: 'outputs',
  overallGroupByFilter: null,
  overallTimeFilter: 'all',

  chartOutput: null,
  indicatorTableOutput: null,
  chartOutputStatus: STATUS.IDLE,
  indicatorTableStatus: STATUS.IDLE,
  activeTabIndex: -1,

  tableData: null,
  tableHeaderData: null,
  tabsData: null,
  sliderValues: [],
  chartLabels: [],
  expandKey: '',

  minValue: 0,
  maxValue: 100,
  inputBlockIndicator: null,
  planPageStartDate: null,
  planPageEndDate: null,
  blockDimensions: [],
  indicatorTableData: null,
  multiDimFilters: [],
  resetFilter: false,
  mdFilterItems: [],
  selectedBlockDimension: [],
  Filters: [],
};

const planPageSlice = createSlice({
  initialState,
  name: 'planPageSlice',
  reducers: {
    reset(state) {
      // state.modelDetail = null;
      state.modelStatus = STATUS.IDLE;
      state.blockDetail = null;
      state.inputBlockIndicator = null;
      state.blockStatus = STATUS.IDLE;
      state.drivers = [];
      state.driversStatus = STATUS.IDLE;
      state.metaData = null;
      state.metaDataStatus = STATUS.IDLE;
      state.selectedDriver = null;
      state.showPlanPage = true;
      state.showInputPage = false;
      state.showAuthModel = false;

      state.dimension = null;

      state.tableFilter = null;
      state.chartFilter = null;

      state.overallIndicatorFilter = 'outputs';
      state.overallGroupByFilter = null;
      state.overallTimeFilter = 'all';

      state.chartOutput = null;
      state.indicatorTableOutput = null;
      state.indicatorTableStatus = STATUS.IDLE;
      state.chartOutputStatus = STATUS.IDLE;
      state.activeTabIndex = -1;

      state.tableData = null;
      state.tableHeaderData = null;
      state.tabsData = null;
      state.expandKey = '';
      state.chartType = '';
      state.planPageStartDate = null;
      state.planPageEndDate = null;
      state.minValue = 0;
      state.maxValue = 100;
      state.sliderValues = [];
      state.chartLabels = [];
      state.chartInnerWidth = 0;
      state.indicatorTableData = null;
      state.selectedBlockDimension = [];
    },
    setResetFilter: (state, action) => {
      state.resetFilter = action.payload;
    },
    setFilterItems: (state, action) => {
      const newData = [...current(state.mdFilterItems)];
      const foundItem =
        newData.length > 0 ? newData.find((item: any) => item.id === action.payload.id) : null;
      if (foundItem) {
        const index = newData.indexOf(foundItem);
        newData[index] = action.payload;
      } else {
        newData.push(action.payload);
      }
      state.mdFilterItems = newData;
    },
    setMultiDimFilter: (state, action) => {
      const newData = [...current(state.multiDimFilters)];
      const foundItem = newData.find((item: any) => item.dimId === action.payload.dimId);

      if (foundItem) {
        const index = newData.indexOf(foundItem);
        newData[index] = action.payload;
      } else {
        newData.push(action.payload);
      }
      state.multiDimFilters = newData;
    },
    resetMultiDimFilter: (state) => {
      state.multiDimFilters = [];
      state.mdFilterItems = [];
    },
    setDriver: (state, action) => {
      if (!action.payload) {
        state.showPlanPage = true;
        state.showInputPage = false;
      } else if (action.payload && action.payload.type === 'dimension') {
        state.showPlanPage = false;
        state.showInputPage = true;
      } else {
        state.showPlanPage = true;
        state.showInputPage = true;
      }

      state.selectedDriver = action.payload;
    },
    closeInputSection: (state) => {
      state.showInputPage = false;
      state.selectedDriver = null;
    },
    toggleExpand: (state) => {
      state.showPlanPage = !state.showPlanPage;
    },
    onErrorShow: (state, action) => {
      if (action.payload) {
        state.showPlanPage = false;
        state.showInputPage = true;
      }
    },
    toggleAuthModel: (state) => {
      state.showAuthModel = !state.showAuthModel;
    },
    setChartType: (state, action) => {
      state.chartType = action.payload;
    },
    groupByFilterChange: (state, action) => {
      state.overallGroupByFilter = action.payload;
    },
    timeFilterChange: (state, action) => {
      state.overallTimeFilter = action.payload;
      const timeGranularity = state.modelDetail?.time_properties?.time_granularity
        ? (state.modelDetail?.time_properties?.time_granularity as String).toUpperCase()
        : 'M';
      if (state.overallTimeFilter !== 'all' && timeGranularity !== 'Y') {
        state.chartFilter = 'M';
      }
    },
    indicatorFilterChange: (state, action) => {
      state.overallIndicatorFilter = action.payload;
    },
    setActiveTabIndex: (state, action) => {
      state.activeTabIndex = action?.payload;
      if (state?.chartOutput) {
        state.chartType = state?.chartOutput[state?.activeTabIndex]?.chart_type ?? 'line';
      }
    },
    setChartFilter: (state, action) => {
      state.chartFilter = action.payload;
      if (state.chartFilter === 'Y') {
        state.overallTimeFilter = 'all';
      }
      formatChartData(state, false);
    },
    setTableFilter: (state, action) => {
      state.expandKey = '';
      state.tableFilter = action.payload;
      formatTableData(state);
    },
    setExpandKey: (state, action) => {
      state.expandKey = action.payload;
      formatTableData(state);
    },
    setEmptyData: (state, action) => {
      state.chartOutput = null;
      state.tabsData = null;
      if (action.payload) state.indicatorTableOutput = null;
    },
    setPlanPageDate: (state, action) => {
      state.planPageStartDate = action.payload.startDatePlanPage;
      state.planPageEndDate = action.payload.endDatePlanPage;
      formatChartData(state, false);
      formatTableData(state);
    },
    handleMinMaxSliderValue: (state, action) => {
      state.minValue = action.payload.min;
      state.maxValue = action.payload.max;
      formatChartData(state, true);
    },
    updateSetDriver: (state, action) => {
      const type = action.payload;
      state.selectedDriver = {
        ...state.selectedDriver,
        input: {
          ...state.selectedDriver?.input,
          type,
        },
      };
    },
    setPlanPageInnerWidth: (state, action) => {
      state.chartInnerWidth = action.payload;
    },
    handleSidePanel: (state) => {
      state.showSidePanel = !state.showSidePanel;
    },
    handleSelectedBlockDimension: (state, action) => {
      const selectedBlockDimension = current(state.selectedBlockDimension) || [];
      const isDimensionExists = selectedBlockDimension?.find(
        (item: any) => action.payload?.dimensionName in item,
      );
      // If user checked checkbox
      if (action.payload?.type) {
        let test = isDimensionExists;
        test = { ...test };
        const updatedData = {
          ...test,
          [action.payload?.dimensionName]: [
            ...test[action.payload?.dimensionName],
            action.payload?.itemName,
          ],
        };
        const index = selectedBlockDimension?.findIndex((data: any) => {
          const object = Object.keys(data);
          return object[0] === action.payload?.dimensionName;
        });
        state.selectedBlockDimension[index] = updatedData;
        // If user unchecked checkbox
      } else {
        let test = isDimensionExists;
        test = { ...test };
        const updatedData = {
          ...test,
          [action.payload?.dimensionName]: test[action.payload?.dimensionName].filter(
            (item: any) => item !== action.payload?.itemName,
          ),
        };
        const index = selectedBlockDimension?.findIndex((data: any) => {
          const object = Object.keys(data);
          return object[0] === action.payload?.dimensionName;
        });
        state.selectedBlockDimension[index] = updatedData;
        // If user unchecked checkbox
      }
    },
    handleFilters: (state) => {
      const blockDimension = current(state.selectedBlockDimension);
      state.Filters = blockDimension;
    },
    handleSelectAllBlockDimension: (state, action) => {
      const selectedBlockDimension = current(state.selectedBlockDimension) || [];
      const index = selectedBlockDimension?.findIndex((data: any) => {
        const object = Object.keys(data);
        return object[0] === action.payload?.dimensionName;
      });
      if (action.payload?.type) {
        const payload = action.payload?.ischeckedItemsChecked?.map((item: any) => item?.name);
        const data = {
          [action.payload?.dimensionName]: payload,
        };
        state.selectedBlockDimension[index] = data;
      } else {
        const data = {
          [action.payload?.dimensionName]: [],
        };
        state.selectedBlockDimension[index] = data;
      }
    },
    setTableFilterData: (state, action) => {
      state.indicatorTableData = action?.payload;
    },
    resetSelectedBlockDimension: (state) => {
      const blockDimension = current(state.blockDimensions) || [];
      const transformedData = blockDimension?.dimensions.map(({ name, items }: any) => ({
        [name]: items,
      }));
      state.selectedBlockDimension = transformedData;
      state.Filters = transformedData;
      state.blockDimensions = blockDimension;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchModel.fulfilled, (state, action) => {
        state.modelDetail = action.payload;
        state.chartFilter = state.modelDetail?.time_properties?.time_granularity
          ? (state.modelDetail?.time_properties?.time_granularity as String).toUpperCase()
          : 'M';
        state.tableFilter = state.modelDetail?.time_properties?.time_granularity
          ? (state.modelDetail?.time_properties?.time_granularity as String).toUpperCase()
          : 'M';
        state.modelStatus = STATUS.SUCCESS;
      })

      .addCase(FetchPlangPageIndicatorTableData.fulfilled, (state, action) => {
        state.indicatorTableData = action?.payload;
      })

      .addCase(UpdatePlangPageIndicatorTableData.fulfilled, (state, action) => {
        state.indicatorTableData = action?.payload;
      })

      .addCase(fetchBlock.fulfilled, (state, action) => {
        state.blockDetail = action.payload;
        state.inputBlockIndicator = action?.payload?.indicators;
        const reguiredDimension = state.blockDetail.dimensions.find(
          (dim: DimItem) => dim.name.toLowerCase() !== 'time',
        );
        state.dimension = reguiredDimension;

        state.blockStatus = STATUS.SUCCESS;
      })

      .addCase(fetchBlockMetadata.fulfilled, (state, action) => {
        state.metaData = action.payload;
        state.metaDataStatus = STATUS.SUCCESS;
        state.overallGroupByFilter =
          state.metaData.dimensions && state.metaData.dimensions.length > 0
            ? getClosestToTargetItemCountDimension(state?.metaData?.dimensions)
            : null;
      })

      .addCase(fetchBlockDrivers.fulfilled, (state, action) => {
        state.drivers = action.payload.drivers;
        state.drivers?.map((driver: any) => {
          let driverToSelect = null;
          if (state.selectedDriver) {
            if (
              driver?.type === 'indicator' &&
              state.selectedDriver?.type === 'indicator' &&
              driver?.id === state.selectedDriver?.id
            ) {
              driverToSelect = driver;
            } else if (
              driver?.type === 'property' &&
              state.selectedDriver?.type === 'property' &&
              driver?.property_id === state.selectedDriver?.property_id
            ) {
              driverToSelect = driver;
            } else if (
              driver?.type === 'dimension' &&
              state.selectedDriver?.type === 'dimension' &&
              driver?.dim_id === state.selectedDriver?.dim_id
            ) {
              driverToSelect = driver;
            }
          }
          if (driverToSelect) {
            state.selectedDriver = driverToSelect;
          }
          return driver;
        });
        state.driversStatus = STATUS.SUCCESS;
      })

      .addCase(fetchAnalysisSectionData.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.chartOutput = action.payload;
          if (!state.chartType && state.chartOutput.length > 0) {
            state.chartType = state.chartOutput[0].chart_type;
          }
          formatChartData(state, false);
          state.chartOutputStatus = STATUS.SUCCESS;
        } else if (state.overallIndicatorFilter === 'outputs') {
          state.overallIndicatorFilter = 'inputs_and_outputs';
        } else {
          state.chartOutput = action.payload;
          formatChartData(state, false);
          state.chartOutputStatus = STATUS.SUCCESS;
        }
      })
      .addCase(fetchAnalysisSectionData.rejected, (state) => {
        state.chartOutputStatus = STATUS.FAILED;
      })
      .addCase(fetchTableData.fulfilled, (state, action) => {
        state.indicatorTableOutput = action.payload;
        state.indicatorTableStatus = STATUS.SUCCESS;
        state.expandKey = '';
        formatTableData(state);
      })
      .addCase(fetchTableData.rejected, (state) => {
        state.indicatorTableStatus = STATUS.FAILED;
      })
      .addCase(fetchAnalysisSectionData.pending, (state) => {
        state.chartOutputStatus = STATUS.LOADING;
      })
      .addCase(fetchTableData.pending, (state) => {
        state.indicatorTableStatus = STATUS.LOADING;
      })
      .addCase(FetchBlockItemDimensions.fulfilled, (state, action) => {
        const transformedData = action.payload?.dimensions
          .filter(({ items }: any) => items && items.length > 0)
          .map(({ name, items }: any) => ({
            [name]: items,
          }));
        state.selectedBlockDimension = transformedData;
        state.blockDimensions = action.payload;
      });
  },
});

export const getModelDetails = (state: any) => state.planPageSlice.modelDetail;
export const modelDetailStatus = (state: any) => state.planPageSlice.modelStatus;

export const getBlockDetails = (state: any) => state.planPageSlice.blockDetail;
export const getBlockInputIndicator = (state: any) => state.planPageSlice.inputBlockIndicator;

export const blockDetailStatus = (state: any) => state.planPageSlice.blockStatus;

export const getBlockDriversData = (state: any) => state.planPageSlice.drivers;
export const blockDriversStatus = (state: any) => state.planPageSlice.driversStatus;

export const getBlockMetadata = (state: any) => state.planPageSlice?.metaData;
export const blockMetadataStatus = (state: any) => state.planPageSlice.metaDataStatus;
export const getSelectedDriver = (state: any) => state.planPageSlice.selectedDriver;
export const planPageShow = (state: any) => state.planPageSlice.showPlanPage;
export const inputsPageShow = (state: any) => state.planPageSlice.showInputPage;
export const ShowSidePanel = (state: any) => state.planPageSlice.showSidePanel;
export const showAuthModel = (state: any) => state.planPageSlice.showAuthModel;
export const getTableFilter = (state: any) => state.planPageSlice.tableFilter;
export const getChartFilter = (state: any) => state.planPageSlice.chartFilter;

export const analysisOutput = (state: any) => state.planPageSlice.tabsData;
export const getIndicatorTableOutput = (state: any) => state.planPageSlice.indicatorTableOutput;
export const getChartOutputData = (state: any) => state.planPageSlice.chartOutput;

export const chartOutputStatus = (state: any) => state.planPageSlice.chartOutputStatus;
export const getIndicatorTableStatus = (state: any) => state.planPageSlice.indicatorTableStatus;

export const overallIndicatorFilter = (state: any) => state.planPageSlice?.overallIndicatorFilter;
export const overallGroupByFilter = (state: any) => state.planPageSlice.overallGroupByFilter;
export const overallTimeFilter = (state: any) => state.planPageSlice.overallTimeFilter;

export const getActiveTabIndex = (state: any) => state.planPageSlice.activeTabIndex;

export const getTableData = (state: any) => state.planPageSlice.tableData;
export const getTableHeaderData = (state: any) => state.planPageSlice.tableHeaderData;
export const getExpandKey = (state: any) => state.planPageSlice.expandKey;

export const getDimension = (state: any) => state.planPageSlice.dimension;
export const getChartType = (state: any) => state.planPageSlice.chartType;

export const getMinSliderValue = (state: any) => state.planPageSlice.minValue;
export const getMaxSliderValue = (state: any) => state.planPageSlice.maxValue;
export const getValuesForSlider = (state: any) => state.planPageSlice.sliderValues;
export const getChartLabels = (state: any) => state.planPageSlice.chartLabels;
export const getPlanPageChartinnerWidth = (state: any) => state.planPageSlice.chartInnerWidth;
export const getBlockDimensions = (state: any) => state.planPageSlice.blockDimensions;
export const getSelectedBlockDimension = (state: any) => state.planPageSlice.selectedBlockDimension;
export const getAppliedFilters = (state: any) => state.planPageSlice.Filters;
export const getIndicatorTableData = (state: any) => state.planPageSlice.indicatorTableData;
export const getmultiDimFiltersItems = (state: any) => state.planPageSlice.multiDimFilters;
export const isResetFilter = (state: any) => state.planPageSlice.resetFilter;
export const mdFilterItems = (state: any) => state.planPageSlice.mdFilterItems;
export const filterStartDate = (state: any) => state.planPageSlice.planPageStartDate;
export const filterEndDate = (state: any) => state.planPageSlice.planPageEndDate;

export const {
  reset,
  setDriver,
  onErrorShow,
  setExpandKey,
  toggleExpand,
  setChartType,
  setEmptyData,
  setChartFilter,
  setTableFilter,
  toggleAuthModel,
  updateSetDriver,
  handleSidePanel,
  handleFilters,
  timeFilterChange,
  setActiveTabIndex,
  closeInputSection,
  groupByFilterChange,
  setPlanPageInnerWidth,
  indicatorFilterChange,
  handleMinMaxSliderValue,
  resetSelectedBlockDimension,
  handleSelectedBlockDimension,
  handleSelectAllBlockDimension,
  setTableFilterData,
  setMultiDimFilter,
  setResetFilter,
  setPlanPageDate,
  setFilterItems,
  resetMultiDimFilter,
} = planPageSlice.actions;

export default planPageSlice.reducer;
