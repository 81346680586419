interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const RemoveIconComponent = ({ width = 20, height = 20, color, style, onClick }: Properties) => {
  return (
    <svg
      cursor={'pointer'}
      width={width}
      height={height}
      fill={color}
      style={style}
      onClick={onClick}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 6.3V7.7H10.5V6.3H3.5ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z'
        fill={color}
      />
    </svg>
  );
};

export default RemoveIconComponent;
