import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';

const generateRandomData = (length: number) => {
  return Array.from({ length }, () => Math.floor(Math.random() * 100));
};

const LineChart = () => {
  const kpiLabel = useMemo(() => Array.from({ length: 12 }, (_, index) => `l ${index + 1}`), []);

  const kpiData1 = useMemo(() => generateRandomData(12), []);
  const kpiData2 = useMemo(() => generateRandomData(12), []); // Additional data for the second line

  return (
    <Box>
      <ReactChart
        type='line'
        data={{
          labels: kpiLabel,
          datasets: [
            {
              label: 'kpi 1',
              data: kpiData1,
              backgroundColor: 'rgba(176, 98, 227, 0.2)', // Transparent fill under the line
              borderColor: '#B062E3', // Line color
              borderWidth: 2,
              tension: 0.4, // Smoothing of the line
            },
            {
              label: 'kpi 2', // Label for the second dataset
              data: kpiData2,
              backgroundColor: 'rgba(98, 176, 227, 0.2)',
              borderColor: '#62B0E3', // Line color
              borderWidth: 2,
              tension: 0.4,
            },
          ],
        }}
        options={{
          aspectRatio: 1,
          plugins: {
            legend: {
              position: 'bottom',
              display: true,
              labels: {
                boxWidth: 20,
                boxHeight: 20,
                color: 'black',
                padding: 10,
                usePointStyle: false,
                font: {
                  size: 13,
                  family: 'Roboto',
                  weight: '500',
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                display: true,
                font: {
                  family: 'Roboto',
                  size: 14,
                },
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: true,
              },
              ticks: {
                display: true,
              },
            },
          },
        }}
        height={383}
        width={420}
      />
    </Box>
  );
};

export default LineChart;
