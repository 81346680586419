/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Flex, Menu, Text, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getWidgetData,
  fetchDashboardGraph,
  handleWidgetUnits,
  fetchDashboardChart,
  getKpiChartData,
  updateChartInstrument,
  getDashboardChartId,
  getChartType,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface DashboardModalUnitsProperties {
  chartUnits?: any;
  isEditable?: any;
}

const DashboardModalUnits = ({ chartUnits, isEditable }: DashboardModalUnitsProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const chartId = useSelector(getDashboardChartId);
  const kpiChartData = useSelector(getKpiChartData);
  const [units, setUnits] = useState('Auto');
  const chartType = useSelector(getChartType);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const widgetData = useSelector(getWidgetData);

  useMemo(() => {
    if (isEditable && chartUnits) {
      chartUnits === 'k'
        ? setUnits('Thousands')
        : chartUnits === 'mn'
        ? setUnits('Millions')
        : chartUnits === 'bn'
        ? setUnits('Billions')
        : setUnits('Auto');
    }
  }, [chartUnits]);

  useMemo(() => {
    if (widgetData.units) {
      widgetData.units === 1
        ? setUnits('Thousands')
        : widgetData.units === 2
        ? setUnits('Millions')
        : widgetData.units === 3
        ? setUnits('Billions')
        : setUnits('Auto');
    }
  }, [widgetData.units]);

  const chartPayload = useMemo(() => {
    if (kpiChartData) {
      const payload = kpiChartData;
      return payload;
    }
    return null;
  }, [kpiChartData]);

  const handleUnitsChange = (unit: number) => {
    dispatch(handleWidgetUnits(unit));
    dispatch(
      updateChartInstrument({
        ...chartPayload,
        formatting: {
          ...chartPayload.formatting,
          units: unit === 1 ? 'k' : unit === 2 ? 'mn' : unit === 3 ? 'bn' : 'auto',
        },
      }),
    ).then(() => {
      dispatch(fetchDashboardChart(chartId));
      if (chartType === 1 || chartType === 3) {
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      }
    });
  };

  return (
    <Flex flexDir={'column'} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Units
      </Text>
      <Menu variant={'dashboardingModal'}>
        {({ isOpen }) => (
          <>
            <MenuButton width={'100%'}>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                backgroundColor={'#FAFAFA'}
                border={'1px #D9D9D9 solid'}
                padding={'.5rem .8rem'}
                borderRadius={'6px'}
                color={'black.600'}
                fontSize={'2xs'}
              >
                <Text>{units}</Text>
                <GoChevronDown size={18} />
              </Flex>
            </MenuButton>
            {isOpen && (
              <MenuList>
                <MenuItem onClick={() => handleUnitsChange(4)}>Auto</MenuItem>
                <MenuItem onClick={() => handleUnitsChange(1)}>Thousands</MenuItem>
                <MenuItem onClick={() => handleUnitsChange(2)}>Millions</MenuItem>
                <MenuItem onClick={() => handleUnitsChange(3)}>Billions</MenuItem>
              </MenuList>
            )}
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default DashboardModalUnits;
