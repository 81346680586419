import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';

import {
  getWidgetData,
  fetchDashboardGraph,
  handleWidgetDecimalPlaces,
  fetchDashboardChart,
  updateChartInstrument,
  getChartType,
  getDashboardChartId,
  getKpiChartData,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface DashboardModalDecimalPlacesProperties {
  chartDecimalPlaces?: any;
  isEditable?: any;
}

const DashboardModalDecimalPlaces = ({
  chartDecimalPlaces,
  isEditable,
}: DashboardModalDecimalPlacesProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const chartId = useSelector(getDashboardChartId);
  const kpiChartData = useSelector(getKpiChartData);
  const widgetData = useSelector(getWidgetData);
  const chartType = useSelector(getChartType);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const [decimalPlaces, setDecimalPlaces] = useState(0);

  useMemo(() => {
    if (isEditable && chartDecimalPlaces) {
      setDecimalPlaces(chartDecimalPlaces);
    }
  }, [chartDecimalPlaces]);

  const chartPayload = useMemo(() => {
    if (kpiChartData) {
      const payload = kpiChartData;
      return payload;
    }
    return null;
  }, [kpiChartData]);

  useMemo(() => {
    if (widgetData.decimalPlaces === 0) {
      setDecimalPlaces(0);
    } else {
      setDecimalPlaces(widgetData.decimalPlaces);
    }
  }, [widgetData.decimalPlaces]);

  const handleWidgetChange = (value: any) => {
    setDecimalPlaces(value);
    dispatch(handleWidgetDecimalPlaces(value));
    dispatch(
      updateChartInstrument({
        ...chartPayload,
        formatting: {
          ...chartPayload.formatting,
          decimalPlaces: value,
        },
      }),
    ).then(() => {
      dispatch(fetchDashboardChart(chartId));
      if (chartType === 1 || chartType === 3) {
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      }
    });
  };

  return (
    <Flex flexDir={'column'} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Decimal places
      </Text>
      <NumberInput
        width={'100%'}
        size={'sm'}
        backgroundColor={'#FAFAFA'}
        min={0}
        max={10}
        defaultValue={decimalPlaces}
        value={decimalPlaces}
        onChange={(value) => handleWidgetChange(value)}
      >
        <NumberInputField borderRadius={'6px'} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  );
};

export default DashboardModalDecimalPlaces;
